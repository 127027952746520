<template>
    <div>
        <v-breadcrumbs :items="crumbs" divider="/" style="background-color: #f0f0f0">
        </v-breadcrumbs>
        <v-card class="mx-auto" outlined tile color="#F8F8FF">
        <v-card-title>BayLock</v-card-title>
        <v-container fluid>
            <v-overlay
                    :absolute="true"
                    :value="overlay"
                    >
                        <v-progress-circular
                    indeterminate
                    color="primary"
                    ></v-progress-circular>
                    </v-overlay>
            <v-row>
                <!--
                <v-col cols="12" md="6"><v-btn v-if="validateAccess('system_add')" :loading="btn_loading" :disabled="btn_loading" tile type="submit" small color="primary" @click="viewMore(1, 'create')"><v-icon left x-small dark>fa fa-cogs</v-icon>Create System
                    <template v-slot:loader>
                        <span>Loading...</span>
                    </template>
                    </v-btn>
                </v-col>
                -->
                <v-btn-toggle class="col-12 offset-md-6 col-md-6" style="background: none">
                    <v-btn small disabled>
                        <v-icon small>fa fa-search</v-icon>
                    </v-btn>
                    <v-text-field placeholder="Search" class="search-textfield" hide-details outlined dense v-model="search"></v-text-field>
                </v-btn-toggle>
            </v-row>
            <v-row>
                <v-col cols="12" md="12">
                    <v-autocomplete
                            dense
                            outlined
                            placeholder="All Operators"
                            label="Operator"
                            v-model="operator.selected"
                            @change="execFilter('operator')"
                            :items="operator.items"
                            item-text="name"
                            item-value="OperatorID"
                            :rules="operator.rule"
                            :loading="operator.loader"
                            :disabled="operator.disable"
                            hide-details
                            >
                            </v-autocomplete>
                </v-col>
                <v-col cols="12" md="12">
                    <v-autocomplete
                    dense
                    outlined
                    placeholder="All Car Parks"
                    label="Car Park"
                    v-model="carpark.selected"
                    @change="execFilter('carpark')"
                    :items="carpark.items"
                    item-text="name"
                    item-value="CarParkID"
                    :rules="carpark.rule"
                    :loading="carpark.loader"
                    :disabled="carpark.disable"
                    hide-details
                    >
                    </v-autocomplete>
                </v-col>
                <v-col cols="12" md="12">
                    <v-autocomplete
                    dense
                    outlined
                    placeholder="All Controllers"
                    label="Controller ID - Controller Ref"
                    v-model="controller.selected"
                    @change="execFilter('controller')"
                    :items="controller.items"
                    item-text="name"
                    item-value="WLControllerID"
                    :rules="controller.rule"
                    :loading="controller.loader"
                    :disabled="controller.disable"
                    hide-details
                    >
                    </v-autocomplete>
                </v-col>
                <v-col cols="12" md="12">
                    <v-autocomplete
                    dense
                    outlined
                    placeholder="All Mainboard"
                    label="MB ID - MB Ref"
                    v-model="mainboard.selected"
                    @change="execFilter('mainboard')"
                    :items="mainboard.items"
                    item-text="name"
                    item-value="WLMainboardID"
                    :rules="mainboard.rule"
                    :loading="mainboard.loader"
                    :disabled="mainboard.disable"
                    hide-details
                    >
                    </v-autocomplete>
                </v-col>
                <v-col cols="12" md="12">
                    <v-autocomplete
                    dense
                    outlined
                    placeholder="All Baylock Type"
                    label="Baylock Type"
                    v-model="type.selected"
                    @change="execFilter('type')"
                    :items="type.items"
                    item-text="text"
                    item-value="value"
                    :rules="type.rule"
                    :loading="type.loader"
                    :disabled="type.disable"
                    hide-details
                    >
                    </v-autocomplete>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12" md="8">
                    <v-pagination
                    v-model="page"
                    :length.sync="pages"
                    :total-visible="6"
                    class="mt-4"
                    style="float: left !important"
                    ></v-pagination><br/><p class="text-caption" style="margin-left: 10px">Page {{page}} of {{pages}}</p>
                </v-col>
                <v-col cols="12" md="6"><v-btn v-if="validateAccess('wlwheellock_add')" :loading="btn_loading" :disabled="btn_loading" tile type="submit" small color="primary" @click="viewMore(1, 'create')"><v-icon left x-small dark>fa fa-cogs</v-icon>Create BayLock
                    <template v-slot:loader>
                        <span>Loading...</span>
                    </template>
                    </v-btn>
                </v-col>
            </v-row>
            <v-row dense>
                <v-col><span class="text-body-2">View {{page > 1 ? (page - 1) * 100 + 1 : 1}} - {{getCount}} of {{totalrecords}}</span></v-col>
                <v-col cols="12" md="12" id="table-parent">
                    <div id="wrapper1">
                        <div id="div1">
                        </div>
                    </div>
                    <v-data-table
                        calculate-widths
                        :headers="headers"
                        :items="items"
                        hide-default-footer
                        :loading="tableloading"
                        loading-text= "Loading Data... Please wait"
                        :items-per-page="100"
                        id="main-table"
                        dense
                        sort-by="GateControllerID"
                        sort-desc
                    >
                    <template slot="item" slot-scope="props">
                        <tr>
                            <td>{{page > 1 ? (page - 1) * 100 + props.index + 1 : props.index + 1}}</td>
                            <td>{{props.item.WLControllerID}}</td>
                            <td>{{props.item.WLMainboardID}}</td>
                            <!-- <td>{{props.item.WLMainboardRef}}</td> -->
                            <td>{{props.item.OperatorID}}</td>
                            <td>{{props.item.CarParkID}}</td>
                            <td>{{props.item.CarParkUserInputCode}}</td>
                            <td style="white-space: pre">
                                <v-tooltip top v-if="validateAccess('wlwheellock_updatemainboarddetails')">
                                <template v-slot:activator="{ on }">
                                <a style="text-decoration: underline" id="custom-hover" v-on="on" @click.prevent="viewMore(props.item, 'update carpark')">{{props.item.CarParkName}}</a>
                                </template>
                                <span>Update Details</span>
                                </v-tooltip>
                                <span v-else>{{props.item.CarParkName}}</span>
                            </td>
                            <!-- <td v-if="validateAccess('wlwheellock_action')">
                                <v-menu open-on-click top offset-y>
                                    <template v-slot:activator="{ on }">
                                        <v-btn
                                        dark
                                        text
                                        x-small
                                        v-on="on"
                                        >
                                        <v-icon small color="info">fas fa-list</v-icon>
                                        </v-btn>
                                    </template>

                                    <v-list dense>
                                        <v-list-item dense>
                                        <v-list-item-title class="font-weight-bold">BayLock ID | {{props.item.WLWheelLockID}}</v-list-item-title>
                                        </v-list-item>
                                        <v-divider></v-divider>
                                        <v-list-item v-if="false" dense @click="viewReset(props.item)">
                                        <v-list-item-title><v-icon dense x-small color="blue">fa fa-dot-circle</v-icon> Reset BayLock Token</v-list-item-title>
                                        </v-list-item>
                                    </v-list>
                                    </v-menu>
                            </td> -->
                            <td>
                                <v-tooltip top v-if="validateAccess('wlwheellock_reset')">
                                <template v-slot:activator="{ on }">
                                <a style="text-decoration: underline" id="custom-hover" v-on="on" @click.prevent="viewReset(props.item)">{{props.item.WLWheelLockID}}</a>
                                </template>
                                <span>Reset UUID</span>
                                </v-tooltip>
                                <span v-else>{{props.item.WLWheelLockID}}</span>
                            </td>
                            <td>
                                <v-tooltip top v-if="validateAccess('wlwheellock_update')">
                                <template v-slot:activator="{ on }">
                                <a style="text-decoration: underline" id="custom-hover" v-on="on" @click.prevent="viewMore(props.item, 'update')">{{props.item.WLWheelLockRef}}</a>
                                </template>
                                <span>Update Details</span>
                                </v-tooltip>
                                <v-tooltip top v-else-if="validateAccess('wlwheellock_viewdetails')">
                                <template v-slot:activator="{ on }">
                                <a style="text-decoration: underline" id="custom-hover" v-on="on" @click.prevent="viewMore(props.item, 'view')">{{props.item.WLWheelLockRef}}</a>
                                </template>
                                <span>View Details</span>
                                </v-tooltip>
                                <span v-else>{{props.item.WLWheelLockRef}}</span>
                            </td>
                            <td>{{props.item.Type == 1 ? 'WIRED': props.item.Type == 2 ? 'WIRELESS' : 'N/A'}}</td>
                            <td>{{props.item.BayID}}</td>
                            <td>{{props.item.CarParkBayPrefix}}</td>
                            <td>
                                <v-tooltip top v-if="validateAccess('wlwheellock_manual_trigger') && props.item.CarParkBayNo != 'N/A'">
                                <template v-slot:activator="{ on }">
                                    <v-row>
                                        <v-col>{{props.item.CarParkBayNo}}</v-col>
                                        <v-spacer></v-spacer>
                                        <v-col>
                                            <a class="align-self-end" id="custom-hover" v-on="on" @click.prevent="viewMore(props.item, 'down')">
                                                <v-icon   small color="info">fas fa-unlock</v-icon>
                                            </a>
                                        </v-col>
                                    </v-row>
                                                                     
                                </template>
                                    <span>Trigger down</span>
                                </v-tooltip>
                                <span v-else>{{props.item.CarParkBayNo}}</span>
                            </td>
                            <td>{{props.item.WLLockRemark}}</td>
                            <td>
                                <v-tooltip top>
                                <template v-slot:activator="{ on }">
                                <v-chip
                                x-small
                                v-on="on"
                                @click.prevent="viewMore(props.item, 'viewinfo')"
                                class="ma-2"
                                :color="presentCheck(props.item.CarPresentStatus, 'color')"
                                text-color="white">
                                    {{presentCheck(props.item.CarPresentStatus, 'text')}}
                                </v-chip>
                                </template>
                                <span>View Car Present Info</span>
                                </v-tooltip>
                            </td>
                            <td>
                                <v-tooltip top>
                                <template v-slot:activator="{ on }">
                                <a style="text-decoration: underline" id="custom-hover" v-on="on" @click.prevent="viewMore(props.item, 'viewconnection')">Info</a>
                                </template>
                                <span>View Connection</span>
                                </v-tooltip>
                            </td>
                            <td>
                                <v-chip
                                x-small
                                class="ma-2"
                                :color="statusCheck(props.item.WLLockEnabled, 'color')"
                                text-color="white">
                                    {{statusCheck(props.item.WLLockEnabled, 'text')}}
                                </v-chip>
                            </td>
                        </tr>
                    </template>
                    </v-data-table>
                </v-col>
            </v-row>
        </v-container>
        </v-card>
        <v-snackbar
        v-model="swal.notification"
        top
        :color="swal.scolor"
        >
            {{ swal.message }}
        </v-snackbar>
        <edit :dialog="ma.modal_update" :customText="ma.customText" :modal_action_type="ma.modal_action_type" :modalInfo="ma.modalInfo" :readonly="ma.readonly" :allInfo="ma.allInfo" :operator="operator.itemsFull" :carpark="carpark.itemsFull" :controller="controller.itemsFull" :mainboard="mainboard.itemsFull" :bays="bays" @confirm="onConfirm" @closed="onClosed"></edit>
        <editcarpark :dialog="ma.modal_updatecarpark" :customText="ma.customText" :modal_action_type="ma.modal_action_type" :modalInfo="ma.modalInfo" :readonly="ma.readonly" :allInfo="ma.allInfo" :operator="operator.itemsFull" :carpark="carpark.itemsFull" :controller="controller.itemsFull" :mainboard="mainboard.itemsFull" :bays="bays" @confirm="onConfirm" @closed="onClosed"></editcarpark>
        <viewconnection :dialog="ma.modal_viewconnection" :customText="ma.customText" :modal_action_type="ma.modal_action_type" :modalInfo="ma.modalInfo" :readonly="ma.readonly" :allInfo="ma.allInfo" @confirm="onConfirm" @closed="onClosed"></viewconnection>
        <viewinfo :dialog="ma.modal_viewinfo" :customText="ma.customText" :modal_action_type="ma.modal_action_type" :modalInfo="ma.modalInfo" :readonly="ma.readonly" :allInfo="ma.allInfo" @confirm="onConfirm" @closed="onClosed"></viewinfo>
        <create :dialog="ma.modal" :customText="ma.customText" :modal_action_type="ma.modal_action_type" :modalInfo="ma.modalInfo" :allInfo="ma.allInfo" :carpark="carpark.itemsFull" :operator="operator.itemsFull" :controller="controller.itemsFull" :mainboard="mainboard.itemsFull" :bays="bays" @confirm="onConfirm" @closed="onClosed"></create>
        <reset :dialog="ma.modal_reset" :customText="ma.customText" :modal_action_type="ma.modal_action_type" :modalInfo="ma.modalInfo" :allInfo="ma.allInfo" :carpark="carpark.itemsFull" :operator="operator.itemsFull" @confirm="onConfirm" @closed="onClosed"></reset>
        <alert :dialog="ma.modal_status" :customText="ma.customText" :modal_action_type="ma.modal_action_type" :modalInfo="ma.modalInfo" @confirm="onConfirm" @closed="onClosed"></alert>
        <manualtrigger :dialog="ma.modal_forcedown" :customText="ma.customText" :modal_action_type="ma.modal_action_type" :modalInfo="ma.modalInfo" @confirm="onConfirm" @closed="onClosed"></manualtrigger>
        <!--
        <create :dialog="ma.modal" :customText="ma.customText" :modal_action_type="ma.modal_action_type" :modalInfo="ma.modalInfo" :allInfo="ma.allInfo" @confirm="onConfirm" @closed="onClosed"></create>
        <edit :dialog="ma.modal_update" :customText="ma.customText" :modal_action_type="ma.modal_action_type" :modalInfo="ma.modalInfo" :readonly="ma.readonly" :allInfo="ma.allInfo" @confirm="onConfirm" @closed="onClosed"></edit>
        <alertbox2 :dialog="ex.modal" @closed="onClosed" :content="ex.content"></alertbox2>
        -->
    </div>
</template>
<script>
import timer from '../../assets/js/sleeper';
import create from '.././modals/CreateWheelLock';
import edit from '.././modals/UpdateWheelLock';
import editcarpark from '.././modals/UpdateWheelLockCarPark';
import manualtrigger from '.././modals/AlertManualTriggerBaylock';
import viewconnection from '.././modals/ViewBayLockConnectionInfo';
import viewinfo from '.././modals/ViewBayLockCarPresentInfo';
import alert from '.././modals/Alert';
import reset from '.././modals/WheelLockSettings';
import {ERR} from '../../assets/js/errhandle.js';
import alertbox2 from '../modals/Notification';
import * as moment from "moment";
import * as tz from "moment-timezone";
import {rule} from '../../assets/js/checkPerm';
import * as AppStorage from '../../assets/js/storage';

export default {
    name: 'BayLock',
    title: 'BayLock',
    
    data()  {
        return {
            crumbs: [
                {
                text: 'Home',
                disabled: true,
                href: '#',
                },

                {
                text: 'BayLock',
                disabled: true,
                href: '#',
                },

                {
                text: 'BayLock',
                disabled: true,
                href: '#',
                }
            ],
            items: [],
            search: '',
            headers: [
                { text: '#', value: 'row', width: '3%' },
                { text: 'Controller ID', value: 'WLControllerID', width: '3%' },
                { text: 'MB ID', value: 'WLMainboardID', width: '3%' },
                // { text: 'MB Ref.', value: 'WLMainboardRef', width: '3%' },
                { text: 'CPO ID', value: 'OperatorID', width: '3%' },
                { text: 'CP ID', value: 'CarParkID', width: '3%' },
                { text: 'CP Code', value: 'CarParkUserInputCode', width: '5%' },
                { text: 'Car Park', value: 'CarParkName', width: '5%' },
                // { text: 'Action', value: 'WLWheelLockID', width: '3%' },
                { text: 'BL ID', value: 'WLWheelLockID', width: '3%' },
                { text: 'BL Ref.', value: 'WLWheelLockRef', width: '3%' },
                { text: 'Baylock Type', value: 'WLWheelLockID', width: '3%' },
                { text: 'Bay ID', value: 'WLLockRemark', width: '5%' },
                { text: 'CP Prefix', value: 'WLLockRemark', width: '5%' },
                { text: 'Bay Number', value: 'WLLockRemark', width: '5%' },
                { text: 'Remark', value: 'WLLockRemark', width: '5%' },
                { text: 'Car Present', value: 'WLLockRemark', width: '5%' },
                { text: 'Connection Info', value: 'Connection', width: '5%' },
                { text: 'Status', value: 'WLLockEnabled', width: '5%' },
            ],
            tableloading: false,
            btn_loading: false,
            pages: 0,
            page: 1,
            swal: {
                notification: false,
                message: '',
                scolor: '',
            },
            ma: {
                modal: false,
                modal_action_type: '',
                modalInfo: [],
                customText: [],
                confirmCreate: false,

                modal_status: false,
                modal_update: false,
                modal_updatecarpark: false,
                modal_reset: false,
                readonly: true,
                allInfo: [],

                modal_viewconnection: false,
                modal_viewinfo: false,
                modal_forcedown: false
            },
            pdfurl: '',

            ex: {
                modal: false,
                content: {
                    headline: '',
                    icon: '',
                    chipColor: '',
                    iconColor: '',
                    maintext: '',
                    smalltext: ''
                }
            },

            operator: {
                items: [],
                itemsFull: [],
                selected: '',
                loader: false,
                disable: false,
                rule: []
            },
            carpark: {
                items: [],
                itemsFull: [],
                selected: '',
                loader: false,
                disable: false,
                rule: []
            },
            controller: {
                items: [],
                itemsFull: [],
                selected: '',
                loader: false,
                disable: false,
                rule: []
            },
            mainboard: {
                items: [],
                itemsFull: [],
                selected: '',
                loader: false,
                disable: false,
                rule: []
            },
            type: {
                items: [{text: 'All Baylock Type', value: null},{text: 'WIRED', value: 1}, {text: 'WIRELESS', value: 2}],
                itemsFull: [],
                selected: null,
                loader: false,
                disable: false,
                rule: []
            },
            displaystatus: {
                counter: 0,
                selected: null,
                rerender: 0,
                label: 'All Mobile Display Status'
            },
            toggle_exclusive_search: 0,
            totalrecords: '',
            timer: '',
            autoRefresh: '',
            bays: [],
            overlay: false
        }
    },

    components: {create, edit, editcarpark, alert, alertbox2, reset, viewconnection, viewinfo, manualtrigger},

    async created(){
        await this.getHeaders();
        await this.getOperatorFull();
        await this.getCarParkFull();
        await this.listController();
        await this.listMainboard();
        window.addEventListener("unload", (evt) => this.onUnload({operator: this.operator.selected, carpark: this.carpark.selected, controller: this.controller.selected}, evt));
        this.operator.selected = JSON.parse(AppStorage.get('filter')).operator;
        this.carpark.selected = JSON.parse(AppStorage.get('filter')).carpark;
        this.listItems(1);
        this.horizontalScroll();
    },

    watch: {
        page: {
        handler: function(value) {
            this.items = [];
            this.listItems();
        }
        },

        'search': {
        handler: function(value) {
            this.searchTimeOut();
        }
        },

        'toggle_exclusive_search': {
        handler: function(value) {
            if (this.search != '')
            this.searchTimeOut();
        }
        },

        /*
        'autoRefresh': {
        handler: function(value) {
            //console.log(value +'--'+ this.timer)
            if (value != 0){
                let self = this;
                let truth = false;
                document.addEventListener('visibilitychange', function() {
                    truth = true;
                    if(document.hidden) {
                        clearInterval(self.timer);
                        truth = false;
                    }
                    else {
                        if (truth)
                        self.timer = setInterval(self.listItems, 30000);
                    }
                });

                if (!truth){
                    self.timer = setInterval(self.listItems, 30000);
                }
            }

            else {
                clearInterval(this.timer);
            }
        },
        },
        */
    },

    /*
    async beforeRouteLeave (to, from , next) {
        clearInterval(this.timer);
        next();
    },
    */

    computed: {
        getCount(){
            if (this.items.length > 0)
                return this.items[this.items.length - 1].row;

                else{
                    return '';
                }
            },
    },

    methods: {
        async listItems(pg){
            this.autoRefresh = 1;
            this.items = [];

            try{
                this.tableloading = true;
                let response = await this.$store.dispatch("getWheelLock", {page: pg > 0 ? this.page = pg : this.page, operatorid: this.operator.selected, carparkid: this.carpark.selected, controllerid: this.controller.selected, mainboardid: this.mainboard.selected, search: this.search, type: this.type.selected});
                let response2 = await this.$store.dispatch("getAllCarpark2", {search: ''});

                if (response.data.code == 'AP000'){
                let dt = response.data.record;
                let dt2 = response2.data.record;
                let recordCount = response.data.resultcount;
                this.totalrecords = recordCount;

                for(var i=0; i<dt.length; i++){
                    dt[i].row = this.page > 1 ? (this.page - 1) * 100 + i + 1 : i + 1;
                    dt[i].WLLockCreatedDateTime = moment(dt[i].WLLockCreatedDateTime).format('DD-MM-YYYY hh:mm:ss A');
                    dt[i].WLLockUpdatedDateTime = dt[i].WLLockUpdatedDateTime == null ? "N/A" : moment(dt[i].WLLockUpdatedDateTime).format('DD-MM-YYYY hh:mm:ss A');
                    dt[i].LastPresentDateTime = dt[i].LastPresentDateTime == null ? "N/A" : moment(dt[i].LastPresentDateTime).format('DD-MM-YYYY hh:mm:ss A');
                    dt[i].LastAbsentDateTime = dt[i].LastAbsentDateTime == null ? "N/A" : moment(dt[i].LastAbsentDateTime).format('DD-MM-YYYY hh:mm:ss A');
                    dt[i].LastLockDateTime = dt[i].LastLockDateTime == null ? "N/A" : moment(dt[i].LastLockDateTime).format('DD-MM-YYYY hh:mm:ss A');
                    dt[i].LastUnlockDateTime = dt[i].LastUnlockDateTime == null ? "N/A" : moment(dt[i].LastUnlockDateTime).format('DD-MM-YYYY hh:mm:ss A');
                    dt[i].connected = moment(dt[i].LastConnectedDateTime).isAfter(moment(dt[i].LastDisconnectedDateTime));
                    dt[i].LastConnectedDateTime = dt[i].LastConnectedDateTime == null ? "N/A" : moment(dt[i].LastConnectedDateTime).format('DD-MM-YYYY hh:mm:ss A');
                    dt[i].LastDisconnectedDateTime = dt[i].LastDisconnectedDateTime == null ? "N/A" : moment(dt[i].LastDisconnectedDateTime).format('DD-MM-YYYY hh:mm:ss A');
                    dt[i].WLLockRemark = dt[i].WLLockRemark == "" || dt[i].WLLockRemark == null ? 'N/A' : dt[i].WLLockRemark;
                    dt[i].CarParkBayPrefix = dt[i].CarParkBayPrefix || "N/A";
                    dt[i].CarParkBayNo = dt[i].CarParkBayNo || "N/A";

                    let carpark = dt2.find(item => item.CarParkID == dt[i].CarParkID);
                    dt[i].CarParkUserInputCode = carpark == undefined ? '' : carpark.CarParkUserInputCode;
                    dt[i].CarParkUserInputCode = dt[i].CarParkUserInputCode == "null" || dt[i].CarParkUserInputCode == "" ? "N/A" : dt[i].CarParkUserInputCode;
                }
                
                this.items = dt;
                this.pages = Math.ceil(recordCount / 100);
                this.tableloading = false;
                }
            }

            catch(err){
                console.log(err);
            }
        },

        async getOperatorFull(){
            let list = [];
            this.operator.items = [];
            this.operator.selected = "";

            try{
                this.operator.loader = true;
                this.operator.disable = true;

                let self = this;
                let response = await this.$store.dispatch("listOperator", {search: ''});
                let dt = response.data.record;
                if (response.data.code == 'AP000'){
                    this.operator.items.push({name: "All Operators", OperatorID: ""});
                    this.operator.itemsFull.push({name: "All Operators", OperatorID: ""});

                for(var i=0; i<dt.length; i++){
                    if (dt[i].SystemID === 2){
                        dt[i].name = `${dt[i].OperatorID} - ${dt[i].OperatorName}`;
                        this.operator.items.push({name: dt[i].name, OperatorID: dt[i].OperatorID});
                        this.operator.itemsFull.push(dt[i]);
                    }
                }

                this.operator.selected = this.operator.items[0].OperatorID;
                }

                //this.operator.itemsFull = dt;

                this.operator.loader = false;
                this.operator.disable = false;
            }

            catch(err){
                console.log(err);
            }

            return list;
        },

        async getCarParkFull(){
            let list = [];
            this.carpark.items = [];
            this.carpark.selected = "";

            try{
                this.carpark.loader = true;
                this.carpark.disable = true;

                let self = this;

                if (this.operator.selected){
                    let carpark = this.carpark.itemsFull.filter(item => {
                    return item.OperatorID == self.operator.selected;
                    });

                    this.carpark.items.push({name: "All Car Parks", CarParkID: ""});

                    for(var i=0; i<carpark.length; i++){
                        carpark[i].name = `${carpark[i].CarParkID} - ${carpark[i].CarParkName}`;
                        this.carpark.items.push({name: carpark[i].name, CarParkID: carpark[i].CarParkID});
                    }

                    //this.operator.selected = this.operator.items[1].OperatorID;
                }

                else{
                    let response = await this.$store.dispatch("getAllCarpark2", {});
                    let dt = response.data.record;
                    if (response.data.code == 'AP000'){
                        this.carpark.items.push({name: "All Car Parks", CarParkID: ""});
                        this.carpark.itemsFull.push({name: "All Car Parks", CarParkID: ""});

                    for(var i=0; i<dt.length; i++){
                        if (dt[i].SystemID === 2){
                            dt[i].name = `${dt[i].CarParkID} - ${dt[i].CarParkName}`;
                            this.carpark.items.push({name: dt[i].name, CarParkID: dt[i].CarParkID});
                            this.carpark.itemsFull.push(dt[i]);
                        }
                    }

                    this.carpark.selected = this.carpark.items[0].CarParkID;
                    }
                }

                //this.operator.itemsFull = dt;

                this.carpark.loader = false;
                this.carpark.disable = false;
            }

            catch(err){
                console.log(err);
            }

            return list;
        },

        async listController(){
            this.controller.items = [];
            this.controller.selected = "";
            let self = this;

            try {
                this.controller.loader = true;
                this.controller.disable = true;

                let selectedfilters = {
                        operator: this.operator.selected,
                        carpark: this.carpark.selected
                    };

                let fil = Object.entries(selectedfilters).filter((item) => item[1] !== "");
                let selectedfilter = fil[fil.length - 1] || "";

                if (this.controller.itemsFull.length > 0){
                    let type = '';
                    if (selectedfilter[0] == 'operator') type = "OperatorID";
                    if (selectedfilter[0] == 'carpark') type = "CarParkID";

                    let controller = this.controller.itemsFull.filter(item => {
                    return item[type] == selectedfilter[1];
                    });

                    this.controller.items.push({name: "All Controllers", WLControllerID: ""});

                    for(var i=0; i<controller.length; i++){
                        controller[i].name = `${controller[i].WLControllerID} - ${controller[i].WLControllerRef}`;
                        this.controller.items.push({name: controller[i].name, WLControllerID: controller[i].WLControllerID});
                    }
                }

                else{
                    let response = await this.$store.dispatch("getWheelLockController", {rowresultsperpage: null});
                    let dt = response.data.record;
                    if (response.data.code == 'AP000'){
                        this.controller.items.push({name: "All Controllers", WLControllerID: ""});
                        this.controller.itemsFull.push({name: "All Controllers", WLControllerID: ""});

                    for(var i=0; i<dt.length; i++){
                        if (dt[i].SystemID === 2){
                            dt[i].name = `${dt[i].WLControllerID} - ${dt[i].WLControllerRef}`;
                            this.controller.items.push({name: dt[i].name, WLControllerID: dt[i].WLControllerID});
                            this.controller.itemsFull.push(dt[i]);
                        }
                    }
                }

                this.controller.selected = "";
                }

                this.controller.loader = false;
                this.controller.disable = false;
            }
            
            catch (err) {
                console.log(err);
            }
        },

        async listMainboard(){
            this.mainboard.items = [];
            this.mainboard.selected = "";
            let self = this;

            try {
                this.mainboard.loader = true;
                this.mainboard.disable = true;

                let selectedfilters = {
                        operator: this.operator.selected,
                        carpark: this.carpark.selected,
                        controller: this.controller.selected
                    };

                let fil = Object.entries(selectedfilters).filter((item) => item[1] !== "");
                let selectedfilter = fil[fil.length - 1] || "";

                if (this.mainboard.itemsFull.length > 0){
                    let type = '';
                    if (selectedfilter[0] == 'operator') type = "OperatorID";
                    if (selectedfilter[0] == 'carpark') type = "CarParkID";
                    if (selectedfilter[0] == 'controller') type = "WLControllerID";

                    let mainboard = this.mainboard.itemsFull.filter(item => {
                    return item[type] == selectedfilter[1];
                    });

                    this.mainboard.items.push({name: "All Mainboards", WLMainboardID: ""});

                    for(var i=0; i<mainboard.length; i++){
                        mainboard[i].name = `${mainboard[i].WLMainboardID} - ${mainboard[i].WLMainboardRef}`;
                        this.mainboard.items.push({name: mainboard[i].name, WLMainboardID: mainboard[i].WLMainboardID});
                    }
                }

                else{
                    let response = await this.$store.dispatch("getWheelLockMainboard", {rowresultsperpage: null});
                    let dt = response.data.record;
                    if (response.data.code == 'AP000'){
                        this.mainboard.items.push({name: "All Mainboards", WLMainboardID: ""});
                        this.mainboard.itemsFull.push({name: "All Mainboards", WLMainboardID: ""});

                    for(var i=0; i<dt.length; i++){
                        if (dt[i].SystemID === 2){
                            dt[i].name = `${dt[i].WLMainboardID} - ${dt[i].WLMainboardRef}`;
                            this.mainboard.items.push({name: dt[i].name, WLMainboardID: dt[i].WLMainboardID});
                            this.mainboard.itemsFull.push(dt[i]);
                        }
                    }
                }

                this.mainboard.selected = "";
                }

                this.mainboard.loader = false;
                this.mainboard.disable = false;
            }
            
            catch (err) {
                console.log(err);
            }
        },

        async updateController(v){
            try{
                this.tableloading = true;
                let req = {
                    "wllockid": this.ma.modalInfo.WLWheelLockID,
                    "wlmainboardid": v.mainboard.selected,
                    "bayid": v.bay.selected,
                    "wllockref": v.Ref.item,
                    "wllocktoken" : v.TokenRef.item,
                    "remark": v.Remark.item,
                    "enabled": v.Enabled.item == true ? 1 : false,
                    "type": v.Type.item
                };
                let response = await this.$store.dispatch("updateWheelLock", req)
                if (response.data.code === 'AP000'){
                        await this.listItems(1);

                        this.swal.notification = true;
                        this.swal.scolor = 'green';
                        this.swal.message = `${ERR.HANDLE(response.data.code)}: Wheel Lock has been updated!`;
                }

                else if (response.data.code === 'RD002'){
                        this.swal.notification = true;
                        this.swal.scolor = 'red';
                        this.swal.message = `${ERR.HANDLE(response.data.code)}: Unable to update.`;
                }

                else{
                    this.loading = false;
                    this.swal.notification = true;
                    this.swal.scolor = 'red';
                    this.swal.message = `${ERR.HANDLE(response.data.code)}`;
                }

                this.tableloading = false;
            }

            catch(err){
                console.log(err);
                this.swal.scolor = 'red';
                this.swal.notification = true;
                this.swal.message = "Something went wrong. Please try again later";
                this.tableloading = false;
            }
        },

        async createController(v){
            this.btn_loading = true;
            this.tableloading = true;
            try{
                this.swal.scolor = 'green';
                let req = {
                    "wlmainboardid": v.mainboard.selected,
                    "wllockref": v.wllockref.item,
                    //"bayid": v.bay.selected, removed 28/4/2022
                    "remark": v.remark.item,
                    "type": v.Type.item
                };
                let response = await this.$store.dispatch("createWheelLock", req);
                await timer.sleep(800);
                if (response.data.code === 'AP000'){
                    await this.listItems(1);
                    this.swal.notification = true;
                    this.swal.scolor = 'green';
                    this.swal.message = `${ERR.HANDLE(response.data.code)}: Wheel Lock Created!`;
                }
                else if (response.data.code === 'RD002'){
                    this.swal.notification = true;
                    this.swal.scolor = 'red';
                    this.swal.message = `${ERR.HANDLE(response.data.code)}: Invalid Setup`;
                }

                else{
                    this.loading = false;
                    this.swal.notification = true;
                    this.swal.scolor = 'red';
                    this.swal.message = `${ERR.HANDLE(response.data.code)}`;
                }

                this.tableloading = false;
                this.btn_loading = false;
            }

            catch(err){
                this.swal.notification = true;
                this.swal.scolor = 'red';
                this.swal.message = 'Something went wrong. Please try again later';
                this.tableloading = false;
                this.btn_loading = false;
            }
        },

        async resetController(v){
            try{
                this.tableloading = true;
                let req = {
                    "wllockid": v,
                };
                let response = await this.$store.dispatch("resetWheelLock", req)
                if (response.data.code === 'AP000'){
                        await this.listItems(1);

                        this.swal.notification = true;
                        this.swal.scolor = 'green';
                        this.swal.message = `${ERR.HANDLE(response.data.code)}: Wheel Lock UUID has been reset!`;
                }

                else if (response.data.code === 'RD002'){
                        this.swal.notification = true;
                        this.swal.scolor = 'red';
                        this.swal.message = `${ERR.HANDLE(response.data.code)}: Unable to reset.`;
                }

                else{
                    this.loading = false;
                    this.swal.notification = true;
                    this.swal.scolor = 'red';
                    this.swal.message = `${ERR.HANDLE(response.data.code)}`;
                }

                this.tableloading = false;
            }

            catch(err){
                this.swal.scolor = 'red';
                this.swal.notification = true;
                this.swal.message = "Something went wrong. Please try again later";
                this.tableloading = false;
            }
        },

        getHeaders(){
            this.headers;
            // if (this.validateAccess('wlwheellock_action'))

            // else
            //     this.headers.splice(8, 1);
        },

        validateAccess(val){
            let access = JSON.parse(JSON.stringify(this.$store.getters.getAccess))
            return rule.checkaccess(val, access);
        },

        async listForModal(row){
            let response = await this.$store.dispatch("getWheelLock", {page: this.page, operatorid: this.operator.selected, carparkid: this.carpark.selected, controllerid: this.controller.selected, mainboardid: this.mainboard.selected, search: this.search, baytype: this.type.selected});
            let response2 = await this.$store.dispatch("getAllCarpark2", {search: ''});

            if (response.data.code == 'AP000'){
                let dt = response.data.record;
                let dt2 = response2.data.record;

                for(var i=0; i<dt.length; i++){
                    dt[i].row = this.page > 1 ? (this.page - 1) * 100 + i + 1 : i + 1;
                    dt[i].WLLockCreatedDateTime = moment(dt[i].WLLockCreatedDateTime).format('DD-MM-YYYY hh:mm:ss A');
                    dt[i].WLLockUpdatedDateTime = dt[i].WLLockUpdatedDateTime == null ? "N/A" : moment(dt[i].WLLockUpdatedDateTime).format('DD-MM-YYYY hh:mm:ss A');
                    dt[i].LastPresentDateTime = dt[i].LastPresentDateTime == null ? "N/A" : moment(dt[i].LastPresentDateTime).format('DD-MM-YYYY hh:mm:ss A');
                    dt[i].LastAbsentDateTime = dt[i].LastAbsentDateTime == null ? "N/A" : moment(dt[i].LastAbsentDateTime).format('DD-MM-YYYY hh:mm:ss A');
                    dt[i].LastLockDateTime = dt[i].LastLockDateTime == null ? "N/A" : moment(dt[i].LastLockDateTime).format('DD-MM-YYYY hh:mm:ss A');
                    dt[i].LastUnlockDateTime = dt[i].LastUnlockDateTime == null ? "N/A" : moment(dt[i].LastUnlockDateTime).format('DD-MM-YYYY hh:mm:ss A');
                    dt[i].connected = moment(dt[i].LastConnectedDateTime).isAfter(moment(dt[i].LastDisconnectedDateTime));
                    dt[i].LastConnectedDateTime = dt[i].LastConnectedDateTime == null ? "N/A" : moment(dt[i].LastConnectedDateTime).format('DD-MM-YYYY hh:mm:ss A');
                    dt[i].LastDisconnectedDateTime = dt[i].LastDisconnectedDateTime == null ? "N/A" : moment(dt[i].LastDisconnectedDateTime).format('DD-MM-YYYY hh:mm:ss A');
                    dt[i].WLLockRemark = dt[i].WLLockRemark == "" || dt[i].WLLockRemark == null ? 'N/A' : dt[i].WLLockRemark;
                    dt[i].CarParkBayPrefix = dt[i].CarParkBayPrefix || "N/A";
                    dt[i].CarParkBayNo = dt[i].CarParkBayNo || "N/A";

                    let carpark = dt2.find(item => item.CarParkID == dt[i].CarParkID);
                    dt[i].CarParkUserInputCode = carpark == undefined ? '' : carpark.CarParkUserInputCode;
                    dt[i].CarParkUserInputCode = dt[i].CarParkUserInputCode == "null" || dt[i].CarParkUserInputCode == "" ? "N/A" : dt[i].CarParkUserInputCode;
                }

                return dt.find(id => id.WLWheelLockID === row.WLWheelLockID);
            }
        },

        async viewMore(row, flag){
            this.autoRefresh = 0;
            if (flag == 'delete'){
                this.ma.modal_status = true;
                this.ma.modalInfo = row;
            }

            if (flag == 'create'){
                this.ma.modal = true;
                this.ma.allInfo = this.items;
            }

            if (flag == 'update'){
                this.ma.modal_update = true;
                this.ma.modalInfo = row;
                this.ma.allInfo = this.items;
                this.ma.readonly = false;
            }

            if (flag == 'update carpark'){
                this.ma.modal_updatecarpark = true;
                this.ma.modalInfo = row;
                this.ma.allInfo = this.items;
                this.ma.readonly = false;
            }

            if (flag == 'view'){
                this.ma.modal_update = true;
                this.ma.modalInfo = row;
                this.ma.allInfo = this.items;
                this.ma.readonly = true;
            }

            if (flag == 'viewconnection'){
                this.overlay = true;
                let newrow = await this.listForModal(row);
                this.overlay = false;
                this.ma.modal_viewconnection = true;
                this.ma.modalInfo = newrow;
                this.ma.allInfo = this.items;
                this.ma.readonly = true;
            }

            if (flag == 'viewinfo'){
                this.overlay = true;
                let newrow = await this.listForModal(row);
                this.overlay = false;
                this.ma.modal_viewinfo = true;
                this.ma.modalInfo = newrow;
                this.ma.allInfo = this.items;
                this.ma.readonly = true;
            }
            if(flag == 'down'){
                let newrow = await this.listForModal(row);
                this.overlay = true;
                this.ma.modal_forcedown = true;

                this.ma.modalInfo = newrow;
            }

            this.ma.customText = {text1: "Gate ID", text2: "Name"};
            this.ma.modal_action_type = flag;
        },

        viewReset(row){
            this.autoRefresh = 0;
            this.ma.modal_reset = true;
            this.ma.modalInfo = row;
            this.ma.allInfo = this.items;
            this.ma.readonly = true;
            this.ma.modal_action_type = "reset";
        },

        onClosed(value){
            console.log(value)
            if (value == false){
                this.autoRefresh = 1;
                this.ma.modal = value;
                this.ma.modal_status = value;
                this.ma.modal_update = value;
                this.ma.modal_updatecarpark = value;
                this.ma.modal_reset = value;
                this.ex.modal = value;
                this.ma.modal_viewconnection = value;
                this.ma.modal_viewinfo = value;
                this.ma.modal_forcedown = value;
                /*
                setTimeout(() => {
                this.listItems();
                }, 100);
                */
            }
        },

        onConfirm(value){
            console.log(value)
            if (value.flag === 'create'){
                this.ma.confirmCreate = true;
                this.ma.modal = false;
                this.createController(value);
            }

            if (value.flag === 'update'){
                this.ma.modal_update = false;
                this.ma.modal_updatecarpark = false;
                this.updateController(value);
            }

            if (value === true){
                if (this.ma.modal_action_type == 'reset')
                {
                    this.ma.modal_status = false;
                    this.resetController(this.ma.modalInfo.WLWheelLockID);
                }
            }

            if (value.flag === 'reset'){
                this.ma.modal_reset = false;
                console.log('reset', value);
                this.resetController(this.ma.modalInfo.WLWheelLockID);
            }
            if(value == true && this.ma.modal_forcedown == true){
                this.ma.modal_forcedown = false;
                this.overlay = false
                this.forcedownWheellock();
            }
        },

        async execFilter(type){
            if (type == 'system'){
                await this.getOperatorFull();
                await this.getCarParkFull();
                await this.listController();
                await this.listMainboard();
                await this.searchTimeOut();
            }

            if (type == 'operator'){
                await this.getCarParkFull();
                await this.listController();
                await this.listMainboard();
                await this.searchTimeOut();
            }

            if (type == 'carpark'){
                await this.listController();
                await this.listMainboard();
                await this.searchTimeOut();
            }

            if (type == 'controller'){
                await this.listMainboard();
                await this.searchTimeOut();
            }

            if (type == 'mainboard'){
                await this.searchTimeOut();
            }
            if (type == 'type'){
                await this.searchTimeOut();
            }

            if (type == 'displaystatus'){
                this.displaystatus.counter++;
                if (this.displaystatus.counter % 3 == 0){
                    this.displaystatus.rerender++;
                    this.displaystatus.selected = null;
                    this.displaystatus.label = "All Mobile Display Status";
                }

                if (this.displaystatus.selected === true){
                    this.displaystatus.label = "Mobile Display Status Enable";
                }

                if (this.displaystatus.selected === false){
                    this.displaystatus.label = "Mobile Display Status Disable";
                }
                await this.searchTimeOut();
            }
        },

        searchTimeOut() {

          clearTimeout(this.timeout);

          // Make a new timeout set to go off in 800ms
          this.timeout = setTimeout(() => {
          this.listItems(1);
          }, 800);
        },

        statusCheck(val, typ){
            if (typ == 'text'){
                    if (val === 1){ return 'Enable'}
                    else if (val === 0){return 'Disable'}
                    else {return 'null'}
            }

            if (typ == 'color'){
                    if (val === 1){ return 'green'}
                    else if (val === 0){return 'red'}
                    else {return 'grey'}
            }
        },

        presentCheck(val, typ){
            if (typ == 'text'){
                    if (val === 1){ return 'Present'}
                    else if (val === 0){return 'Absent'}
                    else {return 'null'}
            }

            if (typ == 'color'){
                    if (val === 1){ return 'orange'}
                    else if (val === 0){return 'green'}
                    else {return 'grey'}
            }
        },

        onUnload(filter, evt){
            AppStorage.set('filter', JSON.stringify(filter));
        },

        horizontalScroll(){
            var wrapper1 = document.getElementById('wrapper1');
            var wrapper2 = document.querySelector('.v-data-table__wrapper');
            document.getElementById('wrapper1').style.width = `${String(document.getElementsByClassName("v-data-table__wrapper")[0].offsetWidth)}px`;
            document.getElementById('div1').style.width = `${String(document.getElementsByTagName("table")[0].offsetWidth)}px`;

            if (document.getElementsByClassName("v-data-table-header")[0].offsetWidth < document.getElementById("table-parent").offsetWidth)
                    document.getElementById('wrapper1').style.display = "none";

                else
                    document.getElementById('wrapper1').style.display = "block";

            window.addEventListener('resize', function() {
                document.getElementById('wrapper1').style.width = `${String(document.getElementsByClassName("v-data-table__wrapper")[0].offsetWidth)}px`;
                document.getElementById('div1').style.width = `${String(document.getElementsByTagName("table")[0].offsetWidth)}px`;

                if (document.getElementsByClassName("v-data-table-header")[0].offsetWidth < document.getElementById("table-parent").offsetWidth)
                    document.getElementById('wrapper1').style.display = "none";

                else
                    document.getElementById('wrapper1').style.display = "block";
            });

            const myObserver = new ResizeObserver(entries => {
            entries.forEach(entry => {
                document.getElementById('wrapper1').style.width = `${String(document.getElementsByClassName("v-data-table__wrapper")[0].offsetWidth)}px`;
                document.getElementById('div1').style.width = `${String(document.getElementsByTagName("table")[0].offsetWidth)}px`;
                console.log('table', document.getElementsByClassName("v-data-table__wrapper")[0].offsetWidth);
                console.log('parent', document.getElementById("table-parent").offsetWidth);

                if (document.getElementsByClassName("v-data-table-header")[0].offsetWidth < document.getElementById("table-parent").offsetWidth)
                    document.getElementById('wrapper1').style.display = "none";

                else
                    document.getElementById('wrapper1').style.display = "block";
            });
            });

            const someEl = document.getElementsByTagName("table")[0];
            myObserver.observe(someEl);


            wrapper1.onscroll = function() {
            wrapper2.scrollLeft = wrapper1.scrollLeft;
            };
            wrapper2.onscroll = function() {
            wrapper1.scrollLeft = wrapper2.scrollLeft;
            };
        },

        showConnect(item, typ){
            let ts = item.connected;
            if (typ == 'text'){
                    if (ts)
                        return 'Connected';

                    else
                        return 'Disconnected';
            }

            if (typ == 'color'){
                    if (ts)
                        return 'green';

                    else
                        return 'red';
            }
        },

        async forcedownWheellock(){
            try {
                const req = {
                    "validatorID": null,
                    "wllockID": this.ma.modalInfo.WLWheelLockID,
                    "carparkID": this.ma.modalInfo.CarParkID
                };
                const response  = await this.$store.dispatch('forcedownWheellock', req);
                if (response.data.code === 'AP000'){
                    await this.listItems(1);
                    this.swal.notification = true;
                    this.swal.scolor = 'green';
                    this.swal.message = `${ERR.HANDLE(response.data.code)}: Bay Lock has been unlocked!`;
                }
                else if (response.data.code === 'RD002'){
                    this.swal.notification = true;
                    this.swal.scolor = 'red';
                    this.swal.message = `${ERR.HANDLE(response.data.code)}: Invalid Setup`;
                }

                else{
                    this.loading = false;
                    this.swal.notification = true;
                    this.swal.scolor = 'red';
                    this.swal.message = `${ERR.HANDLE(response.data.code)}`;
                }
            } catch (error) {
                
            }
        },

        beforeDestroy () {
            clearInterval(this.timer);
        },
    }
}
</script>