<template>
    <div>
        <v-breadcrumbs :items="crumbs" divider="/" style="background-color: #f0f0f0">
        </v-breadcrumbs>
        <v-card class="mx-auto" outlined tile color="#F8F8FF">
        <v-card-title>Controller</v-card-title>
        <v-container fluid>
            <v-row>
                <!--
                <v-col cols="12" md="6"><v-btn v-if="validateAccess('system_add')" :loading="btn_loading" :disabled="btn_loading" tile type="submit" small color="primary" @click="viewMore(1, 'create')"><v-icon left x-small dark>fa fa-cogs</v-icon>Create System
                    <template v-slot:loader>
                        <span>Loading...</span>
                    </template>
                    </v-btn>
                </v-col>
                -->
                <v-btn-toggle class="col-12 offset-md-6 col-md-6" style="background: none">
                    <v-btn small disabled>
                        <v-icon small>fa fa-search</v-icon>
                    </v-btn>
                    <v-text-field placeholder="Search" class="search-textfield" hide-details outlined dense v-model="search"></v-text-field>
                </v-btn-toggle>
            </v-row>
            <v-row>
                <v-col cols="12" md="12">
                    <v-autocomplete
                            dense
                            outlined
                            placeholder="All Operators"
                            label="Operator"
                            v-model="operator.selected"
                            @change="execFilter('operator')"
                            :items="operator.items"
                            item-text="name"
                            item-value="OperatorID"
                            :rules="operator.rule"
                            :loading="operator.loader"
                            :disabled="operator.disable"
                            hide-details
                            >
                            </v-autocomplete>
                </v-col>
                <v-col cols="12" md="12">
                    <v-autocomplete
                    dense
                    outlined
                    placeholder="All Car Parks"
                    label="Car Park"
                    v-model="carpark.selected"
                    @change="execFilter('carpark')"
                    :items="carpark.items"
                    item-text="name"
                    item-value="CarParkID"
                    :rules="carpark.rule"
                    :loading="carpark.loader"
                    :disabled="carpark.disable"
                    hide-details
                    >
                    </v-autocomplete>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12" md="8">
                    <v-pagination
                    v-model="page"
                    :length.sync="pages"
                    :total-visible="6"
                    class="mt-4"
                    style="float: left !important"
                    ></v-pagination><br/><p class="text-caption" style="margin-left: 10px">Page {{page}} of {{pages}}</p>
                </v-col>
                <v-col cols="12" md="6"><v-btn v-if="validateAccess('controller_add')" :loading="btn_loading" :disabled="btn_loading" tile type="submit" small color="primary" @click="viewMore(1, 'create')"><v-icon left x-small dark>fa fa-cogs</v-icon>Create Controller
                    <template v-slot:loader>
                        <span>Loading...</span>
                    </template>
                    </v-btn>
                </v-col>
            </v-row>
            <v-row dense>
                <v-col><span class="text-body-2">View {{page > 1 ? (page - 1) * 100 + 1 : 1}} - {{getCount}} of {{totalrecords}}</span></v-col>
                <v-col cols="12" md="12" id="table-parent">
                    <div id="wrapper1">
                        <div id="div1">
                        </div>
                    </div>
                    <v-data-table
                        calculate-widths
                        :headers="headers"
                        :items="items"
                        hide-default-footer
                        :loading="tableloading"
                        loading-text= "Loading Data... Please wait"
                        :items-per-page="100"
                        id="main-table"
                        dense
                        sort-by="GateControllerID"
                        sort-desc
                    >
                    <template slot="item" slot-scope="props">
                        <tr>
                            <td>{{page > 1 ? (page - 1) * 100 + props.index + 1 : props.index + 1}}</td>
                            <td>
                                <v-tooltip top v-if="validateAccess('controller_reset')">
                                <template v-slot:activator="{ on }">
                                <a style="text-decoration: underline" id="custom-hover" v-on="on" @click.prevent="viewReset(props.item)">{{props.item.GateControllerID}}</a>
                                </template>
                                <span>Reset UUID</span>
                                </v-tooltip>
                                <span v-else>{{props.item.GateControllerID}}</span>
                            </td>
                            <td>
                                <v-tooltip top v-if="validateAccess('controller_update')">
                                <template v-slot:activator="{ on }">
                                <a style="text-decoration: underline" id="custom-hover" v-on="on" @click.prevent="viewMore(props.item, 'update')">{{props.item.RefControllerID}}</a>
                                </template>
                                <span>Update Details</span>
                                </v-tooltip>
                                <v-tooltip top v-else-if="validateAccess('controller_viewdetails')">
                                <template v-slot:activator="{ on }">
                                <a style="text-decoration: underline" id="custom-hover" v-on="on" @click.prevent="viewMore(props.item, 'view')">{{props.item.RefControllerID}}</a>
                                </template>
                                <span>View Details</span>
                                </v-tooltip>
                                <span v-else>{{props.item.RefControllerID}}</span>
                            </td>
                            <td>{{props.item.GateControllerType}}</td>
                            <td>{{props.item.OperatorID}}</td>
                            <td>{{props.item.CarParkID}}</td>
                            <td>{{props.item.CarParkUserInputCode}}</td>
                            <td>
                                <v-tooltip top v-if="validateAccess('controller_updatecarparkdetails')">
                                <template v-slot:activator="{ on }">
                                <a style="text-decoration: underline" id="custom-hover" v-on="on" @click.prevent="viewMore(props.item, 'updatecp')">{{props.item.CarParkName}}</a>
                                </template>
                                <span>Update Car Park Details</span>
                                </v-tooltip>
                                <span v-else>{{props.item.CarParkName}}</span>
                            </td>
                            <td>{{props.item.PoleDesc}}</td>
                            <td>{{props.item.GateDesc}}</td>
                            <td>{{props.item.Remark}}</td>
                            <td>
                                <v-tooltip top>
                                <template v-slot:activator="{ on }">
                                <a style="text-decoration: underline" id="custom-hover" v-on="on" @click.prevent="viewMore(props.item, 'viewconnection')">Connection</a>
                                </template>
                                <span>View Connection</span>
                                </v-tooltip>
                            </td>
                            <td>
                                <v-tooltip top>
                                <template v-slot:activator="{ on }">
                                <a style="text-decoration: underline" id="custom-hover" v-on="on" @click.prevent="viewMore(props.item, 'viewinfo')">Info</a>
                                </template>
                                <span>View Info</span>
                                </v-tooltip>
                            </td>
                            <td>
                                <v-chip
                                x-small
                                class="ma-2"
                                :color="statusCheck(props.item.Enabled, 'color')"
                                text-color="white">
                                    {{statusCheck(props.item.Enabled, 'text')}}
                                </v-chip>
                            </td>
                        </tr>
                    </template>
                    </v-data-table>
                </v-col>
            </v-row>
        </v-container>
        </v-card>
        <v-snackbar
        v-model="swal.notification"
        top
        :color="swal.scolor"
        >
            {{ swal.message }}
        </v-snackbar>
        <edit :dialog="ma.modal_update" :customText="ma.customText" :modal_action_type="ma.modal_action_type" :modalInfo="ma.modalInfo" :readonly="ma.readonly" :allInfo="ma.allInfo" :operator="operator.itemsFull" :carpark="carpark.itemsFull" @confirm="onConfirm" @closed="onClosed"></edit>
        <editcp :dialog="ma.modal_updatecp" :customText="ma.customText" :modal_action_type="ma.modal_action_type" :modalInfo="ma.modalInfo" :readonly="ma.readonly" :allInfo="ma.allInfo" :operator="operator.itemsFull" :carpark="carpark.itemsFull" @confirm="onConfirm" @closed="onClosed"></editcp>
        <viewconnection :dialog="ma.modal_viewconnection" :customText="ma.customText" :modal_action_type="ma.modal_action_type" :modalInfo="ma.modalInfo" :readonly="ma.readonly" :allInfo="ma.allInfo" @confirm="onConfirm" @closed="onClosed"></viewconnection>
        <viewinfo :dialog="ma.modal_viewinfo" :customText="ma.customText" :modal_action_type="ma.modal_action_type" :modalInfo="ma.modalInfo" :readonly="ma.readonly" :allInfo="ma.allInfo" @confirm="onConfirm" @closed="onClosed"></viewinfo>
        <create :dialog="ma.modal" :customText="ma.customText" :modal_action_type="ma.modal_action_type" :modalInfo="ma.modalInfo" :allInfo="ma.allInfo" :carpark="carpark.itemsFull" :operator="operator.itemsFull" @confirm="onConfirm" @closed="onClosed"></create>
        <reset :dialog="ma.modal_reset" :customText="ma.customText" :modal_action_type="ma.modal_action_type" :modalInfo="ma.modalInfo" :allInfo="ma.allInfo" :carpark="carpark.itemsFull" :operator="operator.itemsFull" @confirm="onConfirm" @closed="onClosed"></reset>
        <!--
        <create :dialog="ma.modal" :customText="ma.customText" :modal_action_type="ma.modal_action_type" :modalInfo="ma.modalInfo" :allInfo="ma.allInfo" @confirm="onConfirm" @closed="onClosed"></create>
        <edit :dialog="ma.modal_update" :customText="ma.customText" :modal_action_type="ma.modal_action_type" :modalInfo="ma.modalInfo" :readonly="ma.readonly" :allInfo="ma.allInfo" @confirm="onConfirm" @closed="onClosed"></edit>
        <alert :dialog="ma.modal_status" :customText="ma.customText" :modal_action_type="ma.modal_action_type" :modalInfo="ma.modalInfo" @confirm="onConfirm" @closed="onClosed"></alert>
        <alertbox2 :dialog="ex.modal" @closed="onClosed" :content="ex.content"></alertbox2>
        -->
    </div>
</template>
<script>
import timer from '../../assets/js/sleeper';
import create from '.././modals/CreateController';
import edit from '.././modals/UpdateController';
import editcp from '.././modals/UpdateControllerCarPark';
import viewconnection from '.././modals/ViewControllerConnection';
import viewinfo from '.././modals/ViewControllerInfo';
import alert from '.././modals/Alert';
import reset from '.././modals/ControllerSetting';
import {ERR} from '../../assets/js/errhandle.js';
import alertbox2 from '../modals/Notification';
import * as moment from "moment";
import * as tz from "moment-timezone";
import {rule} from '../../assets/js/checkPerm';
import * as AppStorage from '../../assets/js/storage';

export default {
    name: 'Controller',
    title: 'Controller',
    
    data()  {
        return {
            crumbs: [
                {
                text: 'Home',
                disabled: true,
                href: '#',
                },

                {
                text: 'Boom Gate',
                disabled: true,
                href: '#',
                },

                {
                text: 'Controller',
                disabled: true,
                href: '#',
                },

                {
                text: 'Controller',
                disabled: true,
                href: '#',
                },
            ],
            items: [],
            search: '',
            headers: [
                { text: '#', value: 'row', width: '3%' },
                { text: 'Controller ID', value: 'GateControllerID', width: '3%' },
                { text: 'Controller Ref.', value: 'RefControllerID', width: '3%' },
                { text: 'Type', value: 'GateControllerType', width: '3%' },
                { text: 'CPO ID', value: 'OperatorID', width: '3%' },
                { text: 'CP ID', value: 'CarParkID', width: '3%' },
                { text: 'CP Code', value: 'CarParkUserInputCode', width: '5%' },
                { text: 'Car Park', value: 'CarParkName', width: '5%' },
                { text: 'Pole No.', value: 'PoleDesc', width: '5%' },
                { text: 'Gate No.', value: 'GateDesc', width: '5%' },
                { text: 'Remark', value: 'Remark', width: '5%' },
                { text: 'Last Seen', value: 'lastSeenDate', width: '5%' },
                { text: 'Connection Info', value: 'lastSeenDate', width: '5%' },
                { text: 'Status', value: 'Enabled', width: '5%' },
            ],
            tableloading: false,
            btn_loading: false,
            pages: 0,
            page: 1,
            swal: {
                notification: false,
                message: '',
                scolor: '',
            },
            ma: {
                modal: false,
                modal_action_type: '',
                modalInfo: [],
                customText: [],
                confirmCreate: false,

                modal_status: false,
                modal_update: false,
                modal_updatecp: false,
                modal_reset: false,
                readonly: true,
                allInfo: [],

                modal_viewconnection: false,
                modal_viewinfo: false,
            },
            pdfurl: '',

            ex: {
                modal: false,
                content: {
                    headline: '',
                    icon: '',
                    chipColor: '',
                    iconColor: '',
                    maintext: '',
                    smalltext: ''
                }
            },

            operator: {
                items: [],
                itemsFull: [],
                selected: '',
                loader: false,
                disable: false,
                rule: []
            },
            carpark: {
                items: [],
                itemsFull: [],
                selected: '',
                loader: false,
                disable: false,
                rule: []
            },
            displaystatus: {
                counter: 0,
                selected: null,
                rerender: 0,
                label: 'All Mobile Display Status'
            },
            toggle_exclusive_search: 0,
            totalrecords: '',
            timer: '',
            autoRefresh: '',
        }
    },

    components: {create, edit, editcp, alert, alertbox2, reset, viewconnection, viewinfo},

    async created(){
        await this.getHeaders();
        await this.getOperatorFull();
        await this.getCarParkFull();
        window.addEventListener("unload", (evt) => this.onUnload({operator: this.operator.selected, carpark: this.carpark.selected}, evt));
        this.operator.selected = JSON.parse(AppStorage.get('filter')).operator;
        this.carpark.selected = JSON.parse(AppStorage.get('filter')).carpark;
        this.listItems(1);
        this.horizontalScroll();
    },

    watch: {
        page: {
        handler: function(value) {
            this.items = [];
            this.listItems();
        }
        },

        'search': {
        handler: function(value) {
            this.searchTimeOut();
        }
        },

        'toggle_exclusive_search': {
        handler: function(value) {
            if (this.search != '')
            this.searchTimeOut();
        }
        },

        /*
        'autoRefresh': {
        handler: function(value) {
            //console.log(value +'--'+ this.timer)
            if (value != 0){
                let self = this;
                let truth = false;
                document.addEventListener('visibilitychange', function() {
                    truth = true;
                    if(document.hidden) {
                        clearInterval(self.timer);
                        truth = false;
                    }
                    else {
                        if (truth)
                        self.timer = setInterval(self.listItems, 30000);
                    }
                });

                if (!truth){
                    self.timer = setInterval(self.listItems, 30000);
                }
            }

            else {
                clearInterval(this.timer);
            }
        },
        },
        */
    },

    /*
    async beforeRouteLeave (to, from , next) {
        clearInterval(this.timer);
        next();
    },
    */

    computed: {
        getCount(){
            if (this.items.length > 0)
                return this.items[this.items.length - 1].row;

                else{
                    return '';
                }
            },
    },

    methods: {
        async listItems(pg){
            this.autoRefresh = 1;
            this.items = [];

            try{
                this.tableloading = true;
                let response = await this.$store.dispatch("getGateController", {page: pg > 0 ? this.page = pg : this.page, operatorid: this.operator.selected, carparkid: this.carpark.selected, search: this.search });
                let response2 = await this.$store.dispatch("getAllCarpark2", {search: ''});

                if (response.data.code == 'AP000'){
                let dt = response.data.record;
                let dt2 = response2.data.record;
                let recordCount = response.data.recordcount;
                this.totalrecords = recordCount;

                for(var i=0; i<dt.length; i++){
                    dt[i].row = this.page > 1 ? (this.page - 1) * 100 + i + 1 : i + 1;
                    dt[i].CreateDate = moment(dt[i].CreateDate).format('DD-MM-YYYY hh:mm:ss A');
                    dt[i].UpdateDate = dt[i].UpdateDate == null ? "N/A" : moment(dt[i].UpdateDate).format('DD-MM-YYYY hh:mm:ss A');
                    dt[i].lastConnectDate = dt[i].lastConnectDate == null ? "N/A" : moment.unix(dt[i].lastConnectDate).format('DD-MM-YYYY hh:mm:ss A');
                    dt[i].lastDisconnectDate = dt[i].lastDisconnectDate == null ? "N/A" : moment.unix(dt[i].lastDisconnectDate).format('DD-MM-YYYY hh:mm:ss A');
                    dt[i].lastSeenDate2 = dt[i].lastSeenDate == null ? "N/A" : moment.unix(dt[i].lastSeenDate).format('DD-MM-YYYY hh:mm:ss A');
                    dt[i].lastSyncRequestDate = dt[i].lastSyncRequestDate == null ? "N/A" : moment.unix(dt[i].lastSyncRequestDate).format('DD-MM-YYYY hh:mm:ss A');
                    dt[i].lastSyncDate = dt[i].lastSyncDate == null ? "N/A" : moment.unix(dt[i].lastSyncDate).format('DD-MM-YYYY hh:mm:ss A');
                    dt[i].Ref = "N/A";
                    dt[i].GateControllerType = dt[i].GateControllerType == "" || dt[i].GateControllerType == null ? 'N/A' : dt[i].GateControllerType;
                    dt[i].PoleDesc = dt[i].PoleDesc == "" || dt[i].PoleDesc == null ? 'N/A' : dt[i].PoleDesc;
                    dt[i].Remark = dt[i].Remark == "" || dt[i].Remark == null ? 'N/A' : dt[i].Remark;
                    dt[i].GateDesc = dt[i].GateDesc == "" || dt[i].GateDesc == null ? "N/A" : dt[i].GateDesc;

                    let carpark = dt2.find(item => item.CarParkID == dt[i].CarParkID);
                    dt[i].CarParkUserInputCode = carpark == undefined ? '' : carpark.CarParkUserInputCode;
                    dt[i].CarParkUserInputCode = dt[i].CarParkUserInputCode == "null" || dt[i].CarParkUserInputCode == "" ? "N/A" : dt[i].CarParkUserInputCode;
                }
                
                this.items = dt;
                this.pages = Math.ceil(recordCount / 100);
                this.tableloading = false;
                }
            }

            catch(err){
                console.log(err);
            }
        },

        async getOperatorFull(){
            let list = [];
            this.operator.items = [];
            this.operator.selected = "";

            try{
                this.operator.loader = true;
                this.operator.disable = true;

                let self = this;
                let response = await this.$store.dispatch("listOperator", {search: ''});
                let dt = response.data.record;
                if (response.data.code == 'AP000'){
                    this.operator.items.push({name: "All Operators", OperatorID: ""});
                    this.operator.itemsFull.push({name: "All Operators", OperatorID: ""});

                for(var i=0; i<dt.length; i++){
                    if (dt[i].SystemID === 2){
                        dt[i].name = `${dt[i].OperatorID} - ${dt[i].OperatorName}`;
                        this.operator.items.push({name: dt[i].name, OperatorID: dt[i].OperatorID});
                        this.operator.itemsFull.push(dt[i]);
                    }
                }

                this.operator.selected = this.operator.items[0].OperatorID;
                }

                //this.operator.itemsFull = dt;

                this.operator.loader = false;
                this.operator.disable = false;
            }

            catch(err){
                console.log(err);
            }

            return list;
        },

        async getCarParkFull(){
            let list = [];
            this.carpark.items = [];
            this.carpark.selected = "";

            try{
                this.carpark.loader = true;
                this.carpark.disable = true;

                let self = this;

                if (this.operator.selected){
                    let carpark = this.carpark.itemsFull.filter(item => {
                    return item.OperatorID == self.operator.selected;
                    });

                    this.carpark.items.push({name: "All Car Parks", CarParkID: ""});

                    for(var i=0; i<carpark.length; i++){
                        carpark[i].name = `${carpark[i].CarParkID} - ${carpark[i].CarParkName}`;
                        this.carpark.items.push({name: carpark[i].name, CarParkID: carpark[i].CarParkID});
                    }

                    //this.operator.selected = this.operator.items[1].OperatorID;
                }

                else{
                    let response = await this.$store.dispatch("getAllCarpark2", {});
                    let dt = response.data.record;
                    if (response.data.code == 'AP000'){
                        this.carpark.items.push({name: "All Car Parks", CarParkID: ""});
                        this.carpark.itemsFull.push({name: "All Car Parks", CarParkID: ""});

                    for(var i=0; i<dt.length; i++){
                        if (dt[i].SystemID === 2){
                            dt[i].name = `${dt[i].CarParkID} - ${dt[i].CarParkName}`;
                            this.carpark.items.push({name: dt[i].name, CarParkID: dt[i].CarParkID});
                            this.carpark.itemsFull.push(dt[i]);
                        }
                    }

                    this.carpark.selected = this.carpark.items[0].CarParkID;
                    }
                }

                //this.operator.itemsFull = dt;

                this.carpark.loader = false;
                this.carpark.disable = false;
            }

            catch(err){
                console.log(err);
            }

            return list;
        },

        async updateController(v){
            try{
                this.tableloading = true;
                let req = {
                    "GateControllerID": v.GateControllerID.item,
                    "CarParkID": v.carpark.selected,
                    "GateControllerType": v.GateControllerType.selected,
                    "ControllerToken": v.ControllerToken.item,
                    "RefControllerID": v.RefControllerID.item,
                    "PoleDesc": v.PoleDesc.item,
                    "GateDesc": v.GateDesc.item,
                    "Remark": v.Remark.item,
                    "Enabled": v.Enabled.item == true ? 1 : false
                };
                let response = await this.$store.dispatch("updateGateController", req)
                if (response.data.code === 'AP000'){
                        await this.listItems(1);

                        this.swal.notification = true;
                        this.swal.scolor = 'green';
                        this.swal.message = `${ERR.HANDLE(response.data.code)}: Controller has been updated!`;
                }

                else if (response.data.code === 'RD002'){
                        this.swal.notification = true;
                        this.swal.scolor = 'red';
                        this.swal.message = `${ERR.HANDLE(response.data.code)}: Unable to update.`;
                }

                else{
                    this.loading = false;
                    this.swal.notification = true;
                    this.swal.scolor = 'red';
                    this.swal.message = `${ERR.HANDLE(response.data.code)}`;
                }

                this.tableloading = false;
            }

            catch(err){
                this.swal.scolor = 'red';
                this.swal.notification = true;
                this.swal.message = "Something went wrong. Please try again later";
                this.tableloading = false;
            }
        },

        async createController(v){
            this.btn_loading = true;
            this.tableloading = true;
            try{
                this.swal.scolor = 'green';
                let req = {
                    "CarParkID": v.carpark.selected,
                    "GateControllerType": v.GateControllerType.selected,
                    "RefControllerID": v.RefControllerID.item
                };
                let response = await this.$store.dispatch("createGateController", req);
                await timer.sleep(800);
                if (response.data.code === 'AP000'){
                    await this.listItems(1);
                    this.swal.notification = true;
                    this.swal.scolor = 'green';
                    this.swal.message = `${ERR.HANDLE(response.data.code)}: Controller Created!`;
                }
                else if (response.data.code === 'RD002'){
                    this.swal.notification = true;
                    this.swal.scolor = 'red';
                    this.swal.message = `${ERR.HANDLE(response.data.code)}: Invalid Setup`;
                }

                else{
                    this.loading = false;
                    this.swal.notification = true;
                    this.swal.scolor = 'red';
                    this.swal.message = `${ERR.HANDLE(response.data.code)}`;
                }

                this.tableloading = false;
                this.btn_loading = false;
            }

            catch(err){
                this.swal.notification = true;
                this.swal.scolor = 'red';
                this.swal.message = 'Something went wrong. Please try again later';
                this.tableloading = false;
                this.btn_loading = false;
            }
        },

        async resetController(v){
            try{
                this.tableloading = true;
                let req = {
                    "GateControllerID": v.GateControllerID,
                };
                let response = await this.$store.dispatch("resetGateController", req)
                if (response.data.code === 'AP000'){
                        await this.listItems(1);

                        this.swal.notification = true;
                        this.swal.scolor = 'green';
                        this.swal.message = `${ERR.HANDLE(response.data.code)}: Controller UUID has been reset!`;
                }

                else if (response.data.code === 'RD002'){
                        this.swal.notification = true;
                        this.swal.scolor = 'red';
                        this.swal.message = `${ERR.HANDLE(response.data.code)}: Unable to reset.`;
                }

                else{
                    this.loading = false;
                    this.swal.notification = true;
                    this.swal.scolor = 'red';
                    this.swal.message = `${ERR.HANDLE(response.data.code)}`;
                }

                this.tableloading = false;
            }

            catch(err){
                this.swal.scolor = 'red';
                this.swal.notification = true;
                this.swal.message = "Something went wrong. Please try again later";
                this.tableloading = false;
            }
        },

        getHeaders(){
            this.headers;
            // if (this.validateAccess('controller_viewdetails'))
            //     this.headers;

            // else
            //     this.headers.splice(12, 1);
        },

        validateAccess(val){
            let access = JSON.parse(JSON.stringify(this.$store.getters.getAccess))
            return rule.checkaccess(val, access);
        },

        viewMore(row, flag){
            this.autoRefresh = 0;
            if (flag == 'delete'){
                this.ma.modal_status = true;
                this.ma.modalInfo = row;
            }

            if (flag == 'create'){
                this.ma.modal = true;
                this.ma.allInfo = this.items;
            }

            if (flag == 'update'){
                this.ma.modal_update = true;
                this.ma.modalInfo = row;
                this.ma.allInfo = this.items;
                this.ma.readonly = false;
            }

            if (flag == 'updatecp'){
                this.ma.modal_updatecp = true;
                this.ma.modalInfo = row;
                this.ma.allInfo = this.items;
                this.ma.readonly = false;
            }

            if (flag == 'view'){
                this.ma.modal_update = true;
                this.ma.modalInfo = row;
                this.ma.allInfo = this.items;
                this.ma.readonly = true;
            }

            if (flag == 'viewconnection'){
                this.ma.modal_viewconnection = true;
                this.ma.modalInfo = row;
                this.ma.allInfo = this.items;
                this.ma.readonly = true;
            }

            if (flag == 'viewinfo'){
                this.ma.modal_viewinfo = true;
                this.ma.modalInfo = row;
                this.ma.allInfo = this.items;
                this.ma.readonly = true;
            }

            this.ma.customText = {text1: "Gate ID", text2: "Name"};
            this.ma.modal_action_type = flag;
        },

        viewReset(row){
            this.autoRefresh = 0;
            this.ma.modal_reset = true;
            this.ma.modalInfo = row;
            this.ma.allInfo = this.items;
            this.ma.readonly = true;
            
            this.ma.customText = {text1: "Gate ID", text2: "Name"};
        },

        onClosed(value){
            console.log(value)
            if (value == false){
                this.autoRefresh = 1;
                this.ma.modal = value;
                this.ma.modal_status = value;
                this.ma.modal_update = value;
                this.ma.modal_updatecp = value;
                this.ma.modal_reset = value;
                this.ex.modal = value;
                this.ma.modal_viewconnection = value;
                this.ma.modal_viewinfo = value;
                /*
                setTimeout(() => {
                this.listItems();
                }, 100);
                */
            }
        },

        onConfirm(value){
            console.log(value)
            if (value.flag === 'create'){
                this.ma.confirmCreate = true;
                this.ma.modal = false;
                this.createController(value);
            }

            if (value.flag === 'update'){
                this.ma.modal_update = false;
                this.ma.modal_updatecp = false;
                this.updateController(value);
            }

            if (value === true){
                if (this.ma.modal_action_type == 'delete')
                {
                    this.ma.modal_status = false;
                    this.systemRemove();
                }
            }

            if (value.flag === 'reset'){
                this.ma.modal_reset = false;
                console.log('reset', value);
                this.resetController(value);
            }
        },

        async execFilter(type){
            if (type == 'system'){
                await this.getOperatorFull();
                await this.getCarParkFull();
                await this.searchTimeOut();
            }

            if (type == 'operator'){
                await this.getCarParkFull();
                await this.searchTimeOut();
            }

            if (type == 'carpark'){
                await this.searchTimeOut();
            }

            if (type == 'displaystatus'){
                this.displaystatus.counter++;
                if (this.displaystatus.counter % 3 == 0){
                    this.displaystatus.rerender++;
                    this.displaystatus.selected = null;
                    this.displaystatus.label = "All Mobile Display Status";
                }

                if (this.displaystatus.selected === true){
                    this.displaystatus.label = "Mobile Display Status Enable";
                }

                if (this.displaystatus.selected === false){
                    this.displaystatus.label = "Mobile Display Status Disable";
                }
                await this.searchTimeOut();
            }
        },

        searchTimeOut() {

          clearTimeout(this.timeout);

          // Make a new timeout set to go off in 800ms
          this.timeout = setTimeout(() => {
          this.listItems(1);
          }, 800);
        },

        statusCheck(val, typ){
            if (typ == 'text'){
                    if (val === 1){ return 'Enable'}
                    else if (val === 0){return 'Disable'}
                    else {return 'null'}
            }

            if (typ == 'color'){
                    if (val === 1){ return 'green'}
                    else if (val === 0){return 'red'}
                    else {return 'grey'}
            }
        },

        onUnload(filter, evt){
            AppStorage.set('filter', JSON.stringify(filter));
        },

        horizontalScroll(){
            var wrapper1 = document.getElementById('wrapper1');
            var wrapper2 = document.querySelector('.v-data-table__wrapper');
            document.getElementById('wrapper1').style.width = `${String(document.getElementsByClassName("v-data-table__wrapper")[0].offsetWidth)}px`;
            document.getElementById('div1').style.width = `${String(document.getElementsByTagName("table")[0].offsetWidth)}px`;

            if (document.getElementsByClassName("v-data-table-header")[0].offsetWidth < document.getElementById("table-parent").offsetWidth)
                    document.getElementById('wrapper1').style.display = "none";

                else
                    document.getElementById('wrapper1').style.display = "block";

            window.addEventListener('resize', function() {
                document.getElementById('wrapper1').style.width = `${String(document.getElementsByClassName("v-data-table__wrapper")[0].offsetWidth)}px`;
                document.getElementById('div1').style.width = `${String(document.getElementsByTagName("table")[0].offsetWidth)}px`;

                if (document.getElementsByClassName("v-data-table-header")[0].offsetWidth < document.getElementById("table-parent").offsetWidth)
                    document.getElementById('wrapper1').style.display = "none";

                else
                    document.getElementById('wrapper1').style.display = "block";
            });

            const myObserver = new ResizeObserver(entries => {
            entries.forEach(entry => {
                document.getElementById('wrapper1').style.width = `${String(document.getElementsByClassName("v-data-table__wrapper")[0].offsetWidth)}px`;
                document.getElementById('div1').style.width = `${String(document.getElementsByTagName("table")[0].offsetWidth)}px`;
                console.log('table', document.getElementsByClassName("v-data-table__wrapper")[0].offsetWidth);
                console.log('parent', document.getElementById("table-parent").offsetWidth);

                if (document.getElementsByClassName("v-data-table-header")[0].offsetWidth < document.getElementById("table-parent").offsetWidth)
                    document.getElementById('wrapper1').style.display = "none";

                else
                    document.getElementById('wrapper1').style.display = "block";
            });
            });

            const someEl = document.getElementsByTagName("table")[0];
            myObserver.observe(someEl);


            wrapper1.onscroll = function() {
            wrapper2.scrollLeft = wrapper1.scrollLeft;
            };
            wrapper2.onscroll = function() {
            wrapper1.scrollLeft = wrapper2.scrollLeft;
            };
        },

        showConnect(item, typ){
            if (typ == 'text'){
                    if ((Math.round((new Date()).getTime() / 1000) - item) / 60 <= 5)
                        return 'Connected';

                    else
                        return 'Disconnected';
            }

            if (typ == 'color'){
                    if ((Math.round((new Date()).getTime() / 1000) - item) / 60 <= 5)
                        return 'green';

                    else
                        return 'red';
            }
        },

        beforeDestroy () {
            clearInterval(this.timer);
        },
    }
}
</script>