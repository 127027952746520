<template>
    <div>
        <v-breadcrumbs :items="crumbs" divider="/" style="background-color: #f0f0f0">
        </v-breadcrumbs>
        <v-card class="mx-auto" outlined tile color="#F8F8FF">
        <v-card-title>Payment</v-card-title>
        <v-container fluid>
            <v-row>
                <!--
                <v-col cols="12" md="6"><v-btn v-if="validateAccess('system_add')" :loading="btn_loading" :disabled="btn_loading" tile type="submit" small color="primary" @click="viewMore(1, 'create')"><v-icon left x-small dark>fa fa-cogs</v-icon>Create System
                    <template v-slot:loader>
                        <span>Loading...</span>
                    </template>
                    </v-btn>
                </v-col>
                -->
                <v-btn-toggle class="col-12 offset-md-6 col-md-6" style="background: none">
                    <v-btn small disabled>
                        <v-icon small>fa fa-search</v-icon>
                    </v-btn>
                    <v-text-field placeholder="Search" class="search-textfield" hide-details outlined dense v-model="search"></v-text-field>
                </v-btn-toggle>
            </v-row>
            <v-row>
                <v-col cols="12" md="12">
                    <v-autocomplete
                            dense
                            outlined
                            placeholder="All Operators"
                            label="Operator"
                            v-model="operator.selected"
                            @change="execFilter('operator')"
                            :items="operator.items"
                            item-text="name"
                            item-value="OperatorID"
                            :rules="operator.rule"
                            :loading="operator.loader"
                            :disabled="operator.disable"
                            hide-details
                            >
                            </v-autocomplete>
                </v-col>
                <v-col cols="12" md="12">
                    <v-autocomplete
                    dense
                    outlined
                    placeholder="All Car Parks"
                    label="Car Park"
                    v-model="carpark.selected"
                    @change="execFilter('carpark')"
                    :items="carpark.items"
                    item-text="name"
                    item-value="CarParkID"
                    :rules="carpark.rule"
                    :loading="carpark.loader"
                    :disabled="carpark.disable"
                    hide-details
                    >
                    </v-autocomplete>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12" md="12">
                    <vc-date-picker
                    :disabled-dates='dayplusone'
                     :key="counter1" v-model="starttime.item" mode="date" is-range update-on-input :popover="{visibility: 'click'}" @drag="cleardayplusone($event)" @input="listItems(1)" :columns="$screens({ default: 1, lg: 2 })" :masks="{ input: 'DD-MM-YYYY' }">
                      <template v-slot="{ inputValue, inputEvents, togglePopover }">
                            <v-text-field
                                @click="togglePopover({ placement: 'bottom-start' })"
                                :value="inputValue.start != null ? `${inputValue.start} - ${inputValue.end}` : ''"
                                @keyup="starttime.item.start = ''; starttime.item.end = ''"
                                dense
                                v-on="inputEvents"
                                hide-details="auto"
                                label="From Date - To Date"
                                outlined
                                autocomplete="off"
                                hint="You can only select date range not more than 1 month."
                                persistent-hint
                            ></v-text-field>
                        </template>
                      </vc-date-picker>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12"><v-btn outlined tile small color="primary" type="button" v-on:click="getForReport" :disabled="counter1 == 0 || reportloader" :loading="reportloader"><v-icon x-small left>fa fa-download</v-icon> Download Report
                <template v-slot:loader>
                            <span>Downloading Report...</span>
                        </template>
                </v-btn>
                </v-col>
                <v-col cols="12" md="8">
                    <v-pagination
                    v-model="page"
                    :length.sync="pages"
                    :total-visible="6"
                    class="mt-4"
                    style="float: left !important"
                    ></v-pagination><br/><p class="text-caption" style="margin-left: 10px">Page {{page}} of {{pages}}</p>
                </v-col>
            </v-row>
            <v-row dense>
                <v-col><span class="text-body-2">View {{page > 1 ? (page - 1) * 100 + 1 : 1}} - {{getCount}} of {{totalrecords}}</span></v-col>
                <v-col cols="12" md="12" id="table-parent">
                    <div id="wrapper1">
                        <div id="div1">
                        </div>
                    </div>
                    <v-data-table
                        calculate-widths
                        :headers="headers"
                        :items="items"
                        hide-default-footer
                        :loading="tableloading"
                        loading-text= "Loading Data... Please wait"
                        :items-per-page="100"
                        id="main-table"
                        dense
                        sort-by="CreatedDateTime"
                    >
                    <template slot="item" slot-scope="props">
                        <tr>
                            <td>{{page > 1 ? (page - 1) * 100 + props.index + 1 : props.index + 1}}</td>
                            <td>{{props.item.ID}}</td>
                            <td>{{props.item.PassRef}}</td>
                            <td>{{props.item.TicketID}}</td>
                            <td>{{props.item.CarParkID}}</td>
                            <td>{{props.item.CarParkName}}</td>
                            <td>{{props.item.MobileNumber}}</td>
                            <td>{{props.item.CarplateNo}}</td>
                            <td>{{props.item.CreatedDateTime}}</td>
                            <td>{{props.item.Amount_Paid ? props.item.Amount_Paid.toFixed(2): '0.00'}}</td>
                        </tr>
                    </template>
                    </v-data-table>
                </v-col>
            </v-row>
        </v-container>
        </v-card>
        <v-snackbar
        v-model="swal.notification"
        top
        :color="swal.scolor"
        >
            {{ swal.message }}
        </v-snackbar>
        <viewpdf :dialog="ma.modal_pdf" :customText="ma.customText" :modalInfo="ma.modalInfo" :pdfurl="pdfurl" @closed="onClosed"></viewpdf>
        <!--
        <create :dialog="ma.modal" :customText="ma.customText" :modal_action_type="ma.modal_action_type" :modalInfo="ma.modalInfo" :allInfo="ma.allInfo" @confirm="onConfirm" @closed="onClosed"></create>
        <alert :dialog="ma.modal_status" :customText="ma.customText" :modal_action_type="ma.modal_action_type" :modalInfo="ma.modalInfo" @confirm="onConfirm" @closed="onClosed"></alert>
        <alertbox2 :dialog="ex.modal" @closed="onClosed" :content="ex.content"></alertbox2>
        -->
    </div>
</template>
<script>
import timer from '../../assets/js/sleeper';
import create from '.././modals/CreateSystem';
import alert from '.././modals/Alert';
import {ERR} from '../../assets/js/errhandle.js';
import alertbox2 from '../modals/Notification';
import * as moment from "moment";
import * as tz from "moment-timezone";
import {rule} from '../../assets/js/checkPerm';
import viewpdf from '.././modals/ModalPDF2';
import dpicker from "../DatePickerComponent";
import XLSX from 'xlsx';

export default {
    name: 'lprWebPayment',
    title: 'lprWebPayment',
    
    data()  {
        return {
            crumbs: [
                {
                text: 'Home',
                disabled: true,
                href: '#',
                },

                {
                text: 'Report',
                disabled: true,
                href: '#',
                },

                {
                text: 'LPR WEB',
                disabled: true,
                href: '#',
                },

                {
                text: 'Payment',
                disabled: true,
                href: '#',
                }
            ],
            items: [],
            search: '',
            /*
            #, ID, SMS Sent ID, Mobile No., SMS Type, Transaction Date, Status
            */
            headers: [
                { text: '#', value: 'row', width: '3%' },
                { text: 'ID', value: 'ID', width: '3%' },
                { text: 'Trxn Ref.', value: 'PassRef', width: '3%' },
                { text: 'Ticket ID', value: 'TicketID', width: '5%' },
                { text: 'CP ID', value: 'CarparkID', width: '5%' },
                { text: 'Car Park', value: 'CarParkName', width: '5%' },
                { text: 'Mobile No.', value: 'MobileNumber', width: '5%' },
                { text: 'Car Plate', value: 'CarplateNo', width: '5%' },

                { text: 'Transaction Date', value: 'CreatedDateTime', width: '10%', sort: function (a, b) {
                    let aa = moment(a, 'DD-MM-YYYY hh:mm:ss A');
                    let aa2 = aa.toDate();

                    let bb = moment(b, 'DD-MM-YYYY hh:mm:ss A');
                    let bb2 = bb.toDate();

                    return aa2 > bb2 ? -1 : aa2 < bb2 ? 1 : 0;
                }  },
                { text: 'Amount Paid (RM)', value: 'Amount_Paid', width: '5%' },

            ],
            tableloading: false,
            btn_loading: false,
            pages: 0,
            page: 1,
            swal: {
                notification: false,
                message: '',
                scolor: '',
            },
            ma: {
                modal: false,
                modal_action_type: '',
                modalInfo: [],
                customText: [],
                confirmCreate: false,

                modal_status: false,
                modal_update: false,
                readonly: true,
                allInfo: [],

                modal_pdf: '',
            },
            pdfurl: '',

            ex: {
                modal: false,
                content: {
                    headline: '',
                    icon: '',
                    chipColor: '',
                    iconColor: '',
                    maintext: '',
                    smalltext: ''
                }
            },

            operator: {
                items: [],
                itemsFull: [],
                selected: '',
                loader: false,
                disable: false,
                rule: []
            },
            carpark: {
                items: [],
                itemsFull: [],
                selected: '',
                loader: false,
                disable: false,
                rule: []
            },
            smstype: {
                items: [
                    {id: '', label: 'All'},
                    {id: 'PAC', label: 'Activation Code'},
                    {id: 'SOS', label: 'SOS'},
                    {id: 'NTFY', label: 'Notification'}
                ],
                selected: ''
            },
            displaystatus: {
                counter: 0,
                selected: null,
                rerender: 0,
                label: 'All Mobile Display Status'
            },
            toggle_exclusive_search: 0,
            totalrecords: '',
            starttime: {item: {start: '', end: ''}},
            endtime: {item: ''},
            starttimeforapi: '',
            endtimeforapi: '',
            dayplusone: [],
            show: false,
            counter1: 0,
            dataforexport: [],
            reportloader: false
        }
    },

    components: {create, alert, alertbox2, viewpdf},

    async created(){
        await this.getOperatorFull();
        await this.getCarParkFull();
        this.listItems(1);
        this.horizontalScroll();
    },

    watch: {
        page: {
        handler: function(value) {
            this.items = [];
            this.listItems();
        }
        },

        'search': {
        handler: function(value) {
            this.searchTimeOut();
        }
        },

        'toggle_exclusive_search': {
        handler: function(value) {
            if (this.search != '')
            this.searchTimeOut();
        }
        },
    },

    computed: {
        getCount(){
            if (this.items.length > 0)
                return this.items[this.items.length - 1].row;

                else{
                    return '';
                }
            },
    },

    methods: {
        async listItems(pg){
            try{
                this.tableloading = true;
                await this.setTime();
                this.counter1++;
                let response = await this.$store.dispatch("getPayeeReportList", {page: pg > 0 ? this.page = pg : this.page, operatorid: this.operator.selected, carparkid: this.carpark.selected, transactionstartdate: this.starttimeforapi, transactionenddate: this.endtimeforapi, search: this.search, rowresultsperpage: 100,});

                if (response.data.code == 'AP000'){
                    this.$vuetify.goTo(0);

                let dt = response.data.results;
                let recordCount = response.data.count;
                this.totalrecords = recordCount;

                for(var i=0; i<dt.length; i++){
                    dt[i].row = this.page > 1 ? (this.page - 1) * 100 + i + 1 : i + 1;
                    dt[i].CreatedDateTime = moment(dt[i].CreatedDateTime).format('DD-MM-YYYY hh:mm:ss A');
                    
                    dt[i].number = dt[i].number || "N/A";
                }
                
                this.items = dt;
                this.pages = Math.ceil(recordCount / 100);
                this.tableloading = false;
                }
            }

            catch(err){
                console.log(err);
            }
        },

        async getOperatorFull(){
            let list = [];
            this.operator.items = [];
            this.operator.selected = "";

            try{
                this.operator.loader = true;
                this.operator.disable = true;

                let self = this;
                let response = await this.$store.dispatch("listOperator", {search: ''});
                let dt = response.data.record;
                if (response.data.code == 'AP000'){
                    this.operator.items.push({name: "All Operators", OperatorID: ""});
                    this.operator.itemsFull.push({name: "All Operators", OperatorID: ""});

                for(var i=0; i<dt.length; i++){
                    if (dt[i].SystemID === 2){
                        dt[i].name = `${dt[i].OperatorID} - ${dt[i].OperatorName}`;
                        this.operator.items.push({name: dt[i].name, OperatorID: dt[i].OperatorID});
                        this.operator.itemsFull.push(dt[i]);
                    }
                }

                this.operator.selected = this.operator.items[0].OperatorID;
                }

                //this.operator.itemsFull = dt;

                this.operator.loader = false;
                this.operator.disable = false;
            }

            catch(err){
                console.log(err);
            }

            return list;
        },

        async getCarParkFull(){
            let list = [];
            this.carpark.items = [];
            this.carpark.selected = "";

            try{
                this.carpark.loader = true;
                this.carpark.disable = true;

                let self = this;

                if (this.operator.selected){
                    let carpark = this.carpark.itemsFull.filter(item => {
                    return item.OperatorID == self.operator.selected;
                    });

                    this.carpark.items.push({name: "All Car Parks", CarParkID: ""});

                    for(var i=0; i<carpark.length; i++){
                        carpark[i].name = `${carpark[i].CarParkID} - ${carpark[i].CarParkName}`;
                        this.carpark.items.push({name: carpark[i].name, CarParkID: carpark[i].CarParkID});
                    }

                    //this.operator.selected = this.operator.items[1].OperatorID;
                }

                else{
                    let response = await this.$store.dispatch("getAllCarpark2", {});
                    let dt = response.data.record;
                    if (response.data.code == 'AP000'){
                        this.carpark.items.push({name: "All Car Parks", CarParkID: ""});
                        this.carpark.itemsFull.push({name: "All Car Parks", CarParkID: ""});

                    for(var i=0; i<dt.length; i++){
                        dt[i].name = `${dt[i].CarParkID} - ${dt[i].CarParkName}`;
                        this.carpark.items.push({name: dt[i].name, CarParkID: dt[i].CarParkID});
                        this.carpark.itemsFull.push(dt[i]);
                    }

                    this.carpark.selected = this.carpark.items[0].CarParkID;
                    }
                }

                //this.operator.itemsFull = dt;

                this.carpark.loader = false;
                this.carpark.disable = false;
            }

            catch(err){
                console.log(err);
            }

            return list;
        },

        validateAccess(val){
            let access = JSON.parse(JSON.stringify(this.$store.getters.getAccess))
            return rule.checkaccess(val, access);
        },

        viewMore(row, flag){
            //this.autoRefresh = 0;
            if (flag == 'delete'){
                this.ma.modal_status = true;
                this.ma.modalInfo = row;
            }

            if (flag == 'create'){
                this.ma.modal = true;
                this.ma.allInfo = this.items;
            }

            if (flag == 'update'){
                this.ma.modal_update = true;
                this.ma.modalInfo = row;
                this.ma.allInfo = this.items;
                this.ma.readonly = false;
            }

            if (flag == 'view'){
                this.ma.modal_update = true;
                this.ma.modalInfo = row;
                this.ma.allInfo = this.items;
                this.ma.readonly = true;
            }

            if (flag == 'view_pdf'){
                this.ma.modal_pdf = true;
                this.ma.modalInfo = row;
                this.pdfurl = row.InvoiceReceiptURL;
            }

            this.ma.customText = {text1: "Gate ID", text2: "Name"};
            this.ma.modal_action_type = flag;
        },

        update(row){
            this.ma.modalInfo = row;
            this.$router.push({ name: "Update", params: {id: row.ValuePassProductID}});
        },

        onClosed(value){
            console.log(value)
            if (value == false){
                this.ma.modal = value;
                this.ma.modal_status = value;
                this.ma.modal_update = value;
                this.ex.modal = value;
                this.ma.modal_pdf = value;
            }
        },

        /*
        onConfirm(value){
            console.log(value)
            if (value.flag === 'create'){
                this.ma.confirmCreate = true;
                this.ma.modal = false;
                this.addSystem(value);
            }

            if (value.flag === 'update'){
                this.ma.modal_update = false;
                this.systemUpdate(value);
            }

            if (value === true){
                if (this.ma.modal_action_type == 'delete')
                {
                    this.ma.modal_status = false;
                    this.systemRemove();
                }
            }
        },
        */

        async execFilter(type){
            if (type == 'smstype'){
                await this.searchTimeOut();
            }

            if (type == 'system'){
                await this.getOperatorFull();
                await this.getCarParkFull();
                await this.searchTimeOut();
            }

            if (type == 'operator'){
                await this.getCarParkFull();
                await this.searchTimeOut();
            }

            if (type == 'carpark'){
                await this.searchTimeOut();
            }

            if (type == 'displaystatus'){
                this.displaystatus.counter++;
                if (this.displaystatus.counter % 3 == 0){
                    this.displaystatus.rerender++;
                    this.displaystatus.selected = null;
                    this.displaystatus.label = "All Mobile Display Status";
                }

                if (this.displaystatus.selected === true){
                    this.displaystatus.label = "Mobile Display Status Enable";
                }

                if (this.displaystatus.selected === false){
                    this.displaystatus.label = "Mobile Display Status Disable";
                }
                await this.searchTimeOut();
            }
        },

        searchTimeOut() {

          clearTimeout(this.timeout);

          // Make a new timeout set to go off in 800ms
          this.timeout = setTimeout(() => {
          this.listItems(1);
          }, 800);
        },

        statusCheck(val, typ){
            if (typ == 'text'){
                    if (val === '1'){ return 'Success'}
                    else if (val === '0'){return 'Fail'}
                    else {return 'N/A'}
            }

            if (typ == 'color'){
                    if (val === '1'){ return 'green'}
                    else if (val === '0'){return 'red'}
                    else {return 'grey'}
            }
        },

        horizontalScroll(){
            var wrapper1 = document.getElementById('wrapper1');
            var wrapper2 = document.querySelector('.v-data-table__wrapper');
            document.getElementById('wrapper1').style.width = `${String(document.getElementsByClassName("v-data-table__wrapper")[0].offsetWidth)}px`;
            document.getElementById('div1').style.width = `${String(document.getElementsByTagName("table")[0].offsetWidth)}px`;

            if (document.getElementsByClassName("v-data-table-header")[0].offsetWidth < document.getElementById("table-parent").offsetWidth)
                    document.getElementById('wrapper1').style.display = "none";

                else
                    document.getElementById('wrapper1').style.display = "block";

            window.addEventListener('resize', function() {
                document.getElementById('wrapper1').style.width = `${String(document.getElementsByClassName("v-data-table__wrapper")[0].offsetWidth)}px`;
                document.getElementById('div1').style.width = `${String(document.getElementsByTagName("table")[0].offsetWidth)}px`;

                if (document.getElementsByClassName("v-data-table-header")[0].offsetWidth < document.getElementById("table-parent").offsetWidth)
                    document.getElementById('wrapper1').style.display = "none";

                else
                    document.getElementById('wrapper1').style.display = "block";
            });

            const myObserver = new ResizeObserver(entries => {
            entries.forEach(entry => {
                document.getElementById('wrapper1').style.width = `${String(document.getElementsByClassName("v-data-table__wrapper")[0].offsetWidth)}px`;
                document.getElementById('div1').style.width = `${String(document.getElementsByTagName("table")[0].offsetWidth)}px`;
                console.log('table', document.getElementsByClassName("v-data-table__wrapper")[0].offsetWidth);
                console.log('parent', document.getElementById("table-parent").offsetWidth);

                if (document.getElementsByClassName("v-data-table-header")[0].offsetWidth < document.getElementById("table-parent").offsetWidth)
                    document.getElementById('wrapper1').style.display = "none";

                else
                    document.getElementById('wrapper1').style.display = "block";
            });
            });

            const someEl = document.getElementsByTagName("table")[0];
            myObserver.observe(someEl);


            wrapper1.onscroll = function() {
            wrapper2.scrollLeft = wrapper1.scrollLeft;
            };
            wrapper2.onscroll = function() {
            wrapper1.scrollLeft = wrapper2.scrollLeft;
            };
        },
        setTime(){
            let dte = new Date();
            let starttime = this.starttime.item.start !== '' ? moment(new Date(this.starttime.item.start).setHours(16, 0, 0, 0)).subtract(1, 'days').format('YYYY-MM-DD HH:mm:ss') : moment(new Date(moment(new Date()).subtract(31, 'days')).setHours(16, 0, 0, 0)).subtract(1, 'days').format('YYYY-MM-DD HH:mm:ss');
            let endtime = this.starttime.item.end !== '' ? moment(new Date(this.starttime.item.end).setHours(15, 59, 59, 0)).format('YYYY-MM-DD HH:mm:ss') : moment(new Date().setHours(15, 59, 59, 0)).format('YYYY-MM-DD HH:mm:ss');
            if (this.starttime.item.start == ""){
                this.starttime.item.start = new Date(moment(starttime).add(1, 'days'));
                this.starttime.item.end = new Date(endtime);
            }
            console.log('starttime', starttime);
            console.log('endtime', endtime);
            this.starttimeforapi = starttime;
            this.endtimeforapi = endtime;

            // Add a day
            let dte2 = new Date(moment(endtime).add(1, 'days'))
            console.log('dte', new Date(moment(endtime).add(1, 'days')));
            this.dayplusone = null
        },
        async getForReport(){
            this.reportloader = true;
            let response = await this.$store.dispatch("getPayeeReportList", {page: 1, search: this.search, transactionstartdate: this.starttimeforapi, transactionenddate: this.endtimeforapi, rows: 99999, operatorid: this.operator.selected, carparkid: this.carpark.selected });
            let dt = response.data.results;
            const arr = [];
            
            dt.forEach((doc, i) => {
                    let appData = doc;
                    appData.row = this.page > 1 ? (this.page - 1) * 100 + i + 1 : i + 1;
                    appData.CreatedDateTime = doc.CreatedDateTime == null ? "N/A" : moment(new Date(doc.CreatedDateTime)).format('DD-MM-YYYY hh:mm:ss A');

                    arr.push({
                        row: appData.row,
                        ID: appData.ID,
                        PassRef: appData.PassRef,
                        TicketID: appData.TicketID,
                        CarparkID: appData.CarparkID,
                        CarParkName: appData.CarParkName,
                        MobileNumber: appData.MobileNumber,
                        CarplateNo: appData.CarplateNo,
                        CreatedDateTime: appData.CreatedDateTime,
                        Amount_Paid: appData.Amount_Paid,
                    });
            })

            let total1 = arr.reduce(function (acc, obj) { return acc + Number(obj.Amount_Paid); }, 0);

            arr.push({
                        row: '',
                        ID: '',
                        PassRef: '',
                        TicketID: '',
                        CarparkID: '',
                        CarParkName: '',
                        MobileNumber: '',
                        CarplateNo: '',
                        CreatedDateTime: 'Grand Total',
                        Amount_Paid: total1.toFixed(2),
                    });

            this.dataforexport = arr;
            this.reportloader = false;
            this.onexport();
        },
        onexport () { // On Click Excel download button
            // export json to Worksheet of Excel
            // only array possible
            var eHeaders = XLSX.utils.json_to_sheet(this.dataforexport, { origin: 'A6'})
            /*
                    { text: '#', value: 'row', width: '3%' },
                    { text: 'ID', value: 'ID', width: '3%' },
                    { text: 'Trxn Ref.', value: 'PassRef', width: '3%' },
                    { text: 'Ticket ID', value: 'TicketID', width: '5%' },
                    { text: 'CP ID', value: 'CarparkID', width: '5%' },
                    { text: 'Car Park', value: 'CarParkName', width: '5%' },
                    { text: 'Mobile No.', value: 'MobileNumber', width: '5%' },
                    { text: 'Car Plate', value: 'CarplateNo', width: '5%' },

                    { text: 'Transaction Date', value: 'CreatedDateTime', width: '10%', sort: function (a, b) {
                        let aa = moment(a, 'DD-MM-YYYY hh:mm:ss A');
                        let aa2 = aa.toDate();

                        let bb = moment(b, 'DD-MM-YYYY hh:mm:ss A');
                        let bb2 = bb.toDate();

                        return aa2 > bb2 ? -1 : aa2 < bb2 ? 1 : 0;
                    }  },
                    { text: 'Amount Paid (RM)', value: 'Amount_Paid', width: '5%' },
                    */
            // A workbook is the name given to an Excel file
                eHeaders.A6.v = '#';
                eHeaders.B6.v = 'ID';
                eHeaders.C6.v = 'Trxn Ref.';
                eHeaders.D6.v = 'Ticket ID';
                eHeaders.E6.v = 'CP ID';
                eHeaders.F6.v = 'Car Park';
                eHeaders.G6.v = 'Mobile No.';
                eHeaders.H6.v = 'Car Plate';
                eHeaders.I6.v = 'Transaction Date';
                eHeaders.J6.v = 'Amount Paid';

            var wb = XLSX.utils.book_new() // make Workbook of Excel
            const sheet_name_list = wb.SheetNames;
            console.log('sheet_name_list', sheet_name_list);


            XLSX.utils.sheet_add_aoa(eHeaders, [["Snatch Park Bizsolutions Sdn Bhd"]], {origin: 'A1'});
            XLSX.utils.sheet_add_aoa(eHeaders, [["LPR Web Payment Report"]], {origin: 'A3'});
            XLSX.utils.sheet_add_aoa(eHeaders, [[`Date: From ${moment(new Date(this.starttime.item.start)).format('DD-MM-YYYY')} To ${moment(new Date(this.starttime.item.end)).format('DD-MM-YYYY')}`]], {origin: 'A4'});

            // add Worksheet to Workbook
            // Workbook contains one or more worksheets
            XLSX.utils.book_append_sheet(wb, eHeaders, 'LPRWebPayment') // sheetAName is name of Worksheet
            // export Excel file
            XLSX.writeFile(wb, 'LPRWebPayment_'+this.isToday()+'.csv') // name of the file is 'book.xlsx'
        },
        isToday() {
            return moment(new Date()).format('DD-MM-YYYY');
        },
    }
}
</script>