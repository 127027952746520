<template>
    <v-row justify="center">
    <v-dialog v-model="dialogLocal" width="450">
      <v-card>
        <v-form ref="es" action="#" @submit.prevent="beforeCreateForm('create')" lazy-validation>
        <v-card-title class="justify-space-between" style="background-color: #3D348B; color: white; padding-top: 5px; padding-bottom: 5px">
          <span class="headline">LPR Entry</span>
          <v-btn text @click="$emit('closed', false)" fab depressed small><v-icon small color="white">fas fa-times</v-icon></v-btn>
        </v-card-title>
        <v-card-text>
          <v-container fluid>
            <v-row>
                <v-col cols="12" md="12">
                    <v-overlay
                        :absolute="true"
                        :opacity="1"
                        :z-index="999"
                        :value="modal_loader"
                        color="#D2D7DF"
                        >
                        <v-progress-circular indeterminate size="100"></v-progress-circular>
                    </v-overlay>
                    <v-text-field
                        dense
                        outlined
                        v-model="form.mobileid.item"
                        :rules="form.mobileid.rule"
                        label="Car Plate *"
                    ></v-text-field>
                    <v-autocomplete
                    dense
                    outlined
                    placeholder="Select Car Park"
                    label="Car Park"
                    v-model="form.carpark.selected"
                    @change="filterGate"
                    :items="form.carpark.items"
                    item-text="name"
                    item-value="CarParkID"
                    :rules="form.carpark.rule"
                    :loading="form.carpark.loader"
                    :disabled="form.carpark.disable"
                    >
                    </v-autocomplete>
                    <v-autocomplete
                    dense
                    outlined
                    placeholder="Select Entry Gate"
                    label="Entry Gate *"
                    v-model="form.gateid.selected"
                    :items="form.gateid.items"
                    item-text="name"
                    item-value="GateID"
                    :rules="form.gateid.rule"
                    :loading="form.gateid.loader"
                    :disabled="form.gateid.disable"
                    @change="selectedGate"
                    >
                    </v-autocomplete>
                    <vc-date-picker v-model="form.requestedticketentrydatetime.item" popover mode="datetime" locale="en-GB">
                    <template v-slot="{ inputValue, inputEvents, togglePopover }">
                        <v-row dense>
                            <v-col cols="12" sm="12" md="12">
                                <div
                                v-bind:class="validateStartDateMain"
                                class="v-input v-input--is-label-active v-input--is-dirty v-input--dense theme--light v-text-field v-text-field--is-booted v-text-field--enclosed v-text-field--outlined">
                                <div class="v-input__control">
                                <div class="v-input__slot white">
                                    <fieldset aria-hidden="true"><legend :style="focused || form.requestedticketentrydatetime.item ? {'width': '65px'} : {'width': '0px'}"><span>&#8203;</span></legend></fieldset>
                                    <div class="v-text-field__slot">
                                    <label for="input-startdate" v-bind:class="validateStartDate" class="v-label theme--light" style="left: 0px; right: auto; position: absolute;">Entry Date *</label>
                                    <input
                                    id="input-startdate"
                                    @focus="focused = true"
                                    @blur="focused = false"
                                    :value="inputValue"
                                    v-on="inputEvents"
                                    @click="togglePopover({ placement: 'bottom-start', visibility: 'click', positionFixed: true })"
                                    autocomplete="off"
                                    />
                                    </div>
                                </div>
                                <div class="v-text-field__details" v-if="form.requestedticketentrydatetime.item == '' && form.requestedticketentrydatetime.rule.length > 0">
                                    <v-input
                                        error
                                        readonly
                                        v-model="form.requestedticketentrydatetime.item"
                                        :rules="form.requestedticketentrydatetime.rule"
                                        height="0"
                                        class="hide-input-text"
                                    >
                                    </v-input>
                                </div>
                                </div>
                                </div>
                            </v-col>
                        </v-row>
                    </template>
                    </vc-date-picker>
                </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
            <v-btn v-if="!readonly" tile type="submit" small color="primary"><v-icon left x-small dark>fa fa-save</v-icon>Save</v-btn>
            <v-spacer></v-spacer>
            <v-btn tile color="blue darken-1" text @click="$emit('closed', false)">Close</v-btn>
        </v-card-actions>
        </v-form>
        <alertbox :dialog="innerModal" :customText="ma.customText" :modal_action_type="ma.modal_action_type" :modalInfo="ma.modalInfo" @confirm="onConfirm" @closed="onClosed"></alertbox>
      </v-card>
    </v-dialog>
    </v-row>
</template>

<script>
import alertbox from './Alert';
import VueTimepicker from 'vue2-timepicker/src/vue-timepicker.vue'
import * as moment from "moment";

  export default {
    props: ['dialog', 'modalInfo', 'customText', 'system', 'master', 'readonly', 'allInfo', 'carpark'],

    data() {
        return {
            dialogLocal: false,
            tableloading: false,
            swal: {
                notification: false,
                message: '',
                scolor: '',
            },
            innerModal: false,
            ma: {
                modal: false,
                modal_action_type: '',
                modalInfo: [],
                customText: [],
                edit_modal: false,
                view_modal: false,
                confirmEdit: false,
                confirmCreate: false,
            },
            form: {
                mobileid: {item: "", rule: []},
                carpark: {
                    items: [],
                    itemsFull: [],
                    selected: '',
                    loader: false,
                    disable: false,
                    rule: []
                },
                gateid: {
                    items: [],
                    itemsFull: [],
                    selected: '',
                    selectedforapi: {},
                    loader: false,
                    disable: false,
                    rule: []
                },
                requestedticketentrydatetime: {item: "", formatted: "", rule: []},
            },
            modal_loader: false,
            focused: false,
        }
    },

    components: {alertbox, VueTimepicker},

    watch: {
        dialog: {
        handler: function(value) {
            this.modalHandler();
            if(value == true){
                this.handleValue();
            }
        },
        },

        dialogLocal: {
        handler: function(value) {
            this.modalCloseHandler();
        },
        },
    },

    computed: {
        validateStartDate(){
            let classes = '';
            if (this.focused || this.form.requestedticketentrydatetime.item){
                classes += 'primary--text v-label--active';
            }

            if (this.form.requestedticketentrydatetime.item == '' && this.form.requestedticketentrydatetime.rule.length > 0){
                classes += ' error--text';
            }

            return classes;
        },

        validateStartDateMain(){
            let classes = '';
            if (this.focused){
                classes += 'v-input--is-focused primary--text';
            }

            if (this.form.requestedticketentrydatetime.item == '' && this.form.requestedticketentrydatetime.rule.length > 0){
                classes += ' v-input--has-state error--text';
            }

            return classes;
        },
    },

    methods: {
        async getGateFull(){
            let list = [];
            this.form.gateid.items = [];
            this.form.gateid.selected = "";

            try{
                this.form.gateid.loader = true;
                this.form.gateid.disable = true;

                let self = this;
                let response = await this.$store.dispatch("getGateDropDown", {search: ''});
                let dt = response.data.record;
                if (response.data.code == 'AP000'){
                    this.form.gateid.items.push({name: "Select Entry Gate", GateID: "", CarParkID: "", GateUUID: ''});
                    this.form.gateid.itemsFull.push({name: "Select Entry Gate", GateID: "", CarParkID: "", GateUUID: ''});

                for(var i=0; i<dt.length; i++){
                    if (dt[i].IsEntryGate === 1){
                        dt[i].name = `${dt[i].GateID} - ${dt[i].RefGateID}`;
                        this.form.gateid.items.push({name: dt[i].name, GateID: dt[i].RefGateID, CarParkID: dt[i].CarParkID, GateUUID: dt[i].GateUUID});
                        this.form.gateid.itemsFull.push({name: dt[i].name, GateID: dt[i].RefGateID, CarParkID: dt[i].CarParkID, GateUUID: dt[i].GateUUID});
                    }
                }
                }

                this.form.gateid.loader = false;
                this.form.gateid.disable = false;
            }

            catch(err){
                console.log(err);
            }

            return list;
        },

        async beforeCreateForm(flag){
            this.form.mobileid.rule = [
                v => !!v || `This is required`
            ];

            this.form.gateid.rule = [
                v => !!v || `This is required`,
                v => !Number.isNaN(Number(v)) || 'Only integer is allowed'
            ];

            this.form.requestedticketentrydatetime.rule = [
                v => !!v || `This is required`,
            ];

            this.form.gateid.rule = [
                v => !!v || `This is required`,
            ];

            //must set this in order to use 'this' as 'self' inside settimeout etc..
            let self = this;

            setTimeout(function () {
                //self.$refs.image.resetValidation();
                //self.$refs.image.reset();

             if (self.$refs.es.validate()) {
                    self.innerModal = true;
                    self.ma.modalInfo = {id: 1, name: 2};
                    self.ma.modal_action_type = flag;
                }
            });
        },

        modalHandler(){
            this.dialogLocal = !this.dialogLocal;
        },

        modalCloseHandler(){
            if (this.dialogLocal == false){
                this.$emit('closed', false);
            }
        },

        async handleValue(){
            await this.getGateFull();
            this.form.mobileid.rule = [];
            this.form.gateid.rule = []; this.form.requestedticketentrydatetime.rule = [];

            this.form.mobileid.item = "";
            this.form.gateid.item = "";
            this.form.requestedticketentrydatetime.item = "";
            this.form.requestedticketentrydatetime.formatted = "";

            this.form.carpark.selected = "";
            let carpark = [];
            carpark.push({name: 'Select Car Park', CarParkID: ''});
            for (const [index, item] of this.carpark.entries()){
                carpark.push(item);
            }
            this.form.carpark.items = carpark;

            this.form.carpark.selected = "";
        },

        filterGate(){
            this.form.gateid.selected = "";
            if (this.form.carpark.selected !== ""){
                let items = [];
                items.push({name: "Select Entry Gate", GateID: "", CarParkID: ""});

                let carpark = this.carpark.find(item => item.CarParkID == this.form.carpark.selected);
                if (carpark != undefined){
                    let filtered = this.form.gateid.itemsFull.filter(item => item.CarParkID == carpark.CarParkID);

                    if (filtered.length > 0){
                        for(var i=0; i<filtered.length; i++){
                            items.push({name: filtered[i].name, GateID: filtered[i].GateID, CarParkID: filtered[i].CarParkID});
                        }
                    }   
                }

                this.form.gateid.items = items;
            }

            else{
                this.form.gateid.items = this.form.gateid.itemsFull;
            }

            this.form.gateid.loader = false;
            this.form.gateid.disable = false;
        },

        //set the action to be sent to watcher to execute the create/edit function
        async onConfirm(value){
            let formattedrequestedticketentrydatetime = moment(this.form.requestedticketentrydatetime.item).utc().format('YYYY-MM-DD HH:mm:ss');
            this.form.requestedticketentrydatetime.formatted = formattedrequestedticketentrydatetime;

                if (this.ma.modal_action_type == 'create'){
                    console.log('here');
                    this.form.flag = 'entrylpr';
                    this.$emit('confirm', this.form);

                }

                this.innerModal = false;
        },

        onClosed(value){
            if (value == false){
                this.innerModal = value;
            }
        },
        async selectedGate(val){
            this.form.gateid.selectedforapi = this.form.gateid.itemsFull.find((v)=> v.GateID == val);
            console.log(val);
            console.log(this.form.gateid.selectedforapi);
        },
    }
  }
</script>